import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Button, Spinner } from '@blueprintjs/core';
import ReactDataGrid from 'react-data-grid';

import Navigation from '../../navigation';
import UsersHeader from '../../users-header';
import UsersForRole from '../../users-drawers/users-for-role';
import CapabilitiesForRole from '../../users-drawers/capabilities-for-role';
import { organisations } from '../../../services/api';
import * as AuthAPI from '../../../services/auth-api';
import { roleModelColumns } from '../users/role-model-columns';
import { genericSorter } from '../../../utils/dataGridTableSorting';

const Row = styled.div`
  width: 95%;
  max-width: 1600px;
  margin: 1em auto;
`;

const EmptyRowWrapper = styled.div`
  padding: 5em 3em;
  background: #f3f3f3;
  border-radius: 3px;
  color: #555;
`;

class UsersRolesPage extends Component {
  state = {
    roles: [],
    orgs: [],
    rolesLoading: false,
    isRoleUsersDrawerOpen: false,
    isRoleCapabilitiesDrawerOpen: false,
    selectedRoleId: null
  };

  async componentDidMount() {
    try {
      this.setState({ rolesLoading: true });
      const orgs = await organisations.listAll();
      const { data } = await AuthAPI.adminRoles.list(true);

      this.setState({
        roles: data,
        orgs,
        rolesLoading: false
      });
    } catch (e) {
      this.setState({ rolesLoading: false });
      console.error(e);
    }
  }

  setIsRoleUsersDrawerOpen(newValue, selectedRoleId) {
    const isRoleUsersDrawerOpen =
      typeof newValue === 'boolean'
        ? newValue
        : !this.state.isRoleUsersDrawerOpen;

    this.setState({
      isRoleUsersDrawerOpen,
      selectedRoleId
    });
  }

  setIsRoleCapabilitiesDrawerOpen(newValue, selectedRoleId) {
    const isRoleCapabilitiesDrawerOpen =
      typeof newValue === 'boolean'
        ? newValue
        : !this.state.isRoleCapabilitiesDrawerOpen;

    this.setState({
      isRoleCapabilitiesDrawerOpen,
      selectedRoleId
    });
  }

  getActions = roleId => [
    {
      icon: <Button intent="primary" text="Users" />,
      callback: () => this.setIsRoleUsersDrawerOpen(true, roleId)
    },
    {
      icon: <Button intent="primary" text="Capabilities" />,
      callback: () => this.setIsRoleCapabilitiesDrawerOpen(true, roleId)
    }
  ];

  render() {
    const {
      roles = [],
      orgs = [],
      isRoleUsersDrawerOpen,
      isRoleCapabilitiesDrawerOpen,
      rolesLoading,
      selectedRoleId
    } = this.state;

    const columns = roleModelColumns;

    const showEmptyRowWrapper = rolesLoading || !roles.length > 0;

    const selectedRole = selectedRoleId
      ? roles.find(role => role.id === selectedRoleId)
      : {};

    return (
      <Fragment>
        <Navigation />
        <Row>
          <UsersHeader />
        </Row>
        <Row>
          {showEmptyRowWrapper && (
            <EmptyRowWrapper>
              {rolesLoading ? <Spinner /> : <h3>No roles found</h3>}
            </EmptyRowWrapper>
          )}

          {!showEmptyRowWrapper && (
            <ReactDataGrid
              columns={columns}
              rowGetter={i => roles[i]}
              rowsCount={roles.length}
              minHeight={600}
              getCellActions={(col, row) =>
                col.key === 'actions' ? this.getActions(row.id) : null
              }
              enableCellSelect={true}
              onGridSort={(column, sortDir) => {
                this.setState({
                  roles: genericSorter(roles, column, sortDir)
                });
              }}
            />
          )}
        </Row>

        <UsersForRole
          isOpen={isRoleUsersDrawerOpen}
          onClose={() => this.setIsRoleUsersDrawerOpen(false)}
          selectedRoleId={selectedRoleId}
          selectedRoleIsAdmin={selectedRole.auraAdminOnly}
          title={`Role > ${selectedRole.name} > Users`}
          allOrgs={orgs}
        />
        <CapabilitiesForRole
          isOpen={isRoleCapabilitiesDrawerOpen}
          onClose={() => this.setIsRoleCapabilitiesDrawerOpen(false)}
          selectedRoleId={selectedRoleId}
          selectedRoleIsAdmin={selectedRole.auraAdminOnly}
          title={`Role > ${selectedRole.name} > Capabilities`}
          allOrgs={orgs}
        />
      </Fragment>
    );
  }
}

export default UsersRolesPage;
