// @flow

import React from 'react';
import styled from 'styled-components';
import { Popover, Button, MenuItem, Menu } from '@blueprintjs/core';
import { IN, OUT } from '../../constants/line-context';

import type { editingConfigActionT } from './editing-item-form';

export const StyledMenu: any = styled(Menu)`
  max-height: 200px;
  overflow-y: scroll;
`;

type Props = {
  action: editingConfigActionT,
  index: number,
  handleCrossingType(type: string, index: number): void,
};

const CrossingLineDirectionTypeMenu = ({
  handleCrossingType,
  action,
  index,
}: Props): React$Element<any> => {
  return (
    <Popover>
      <Button
        text={action.crossingType ? action.crossingType : 'Select'}
        rightIcon="caret-down"
      />
      <StyledMenu>
        <MenuItem
          key="1"
          text="In"
          onClick={(e) => handleCrossingType(IN, index)}
        />
        <MenuItem
          key="2"
          text="Out"
          onClick={(e) => handleCrossingType(OUT, index)}
        />
      </StyledMenu>
    </Popover>
  );
};

export default CrossingLineDirectionTypeMenu;
