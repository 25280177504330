// @flow

import React from 'react';
import styled from 'styled-components';
import { Tag, Button } from '@blueprintjs/core';
import { Row, Column, col1Flex, col2Flex, col3Flex, col4Flex } from './table';
import { LIGHT_GRAY_BLUE } from '../../styles/constants';
import {
  WITHIN_LOCATION_MOVEMENT,
  WITHIN_LOCATION_ENTRY,
  WITHIN_LOCATION_PASS_BY
} from '../../constants/line-context';
import { LINE } from '../../constants/shapes';

import * as LineContextModel from '../../models/line-context.js';
import * as ShapeModel from '../../models/shape';

const StyledRow = styled(Row)`
  &:hover {
    background-color: ${LIGHT_GRAY_BLUE};
  }
`;
const Table = styled.table`
  text-align: left;
  align-items: left;
  tr > td,
  th {
    padding: 0.5rem 1rem;
  }
`;
const StyledTag = styled(Tag)`
  margin-bottom: 1rem;
`;
const StyledColumn = styled(Column)`
  flex-direction: column;
`;

const Actions = ({ actions }: { actions: LineContextModel.actionsT }) => {
  return actions.map((action, actionIndex) => {
    if (!action[0] || !action[1]) return null;
    const actionsTuple = [action[0], action[1]];
    const actionType = LineContextModel.getActionType(actionsTuple);
    return (
      <tr key={actionIndex}>
        <td>{`${action[0].lineId}`}</td>
        <td>{actionType ? actionType : ''}</td>
      </tr>
    );
  });
};

type Props = {
  lineContext: LineContextModel.t,
  deleteContext(lineContext: LineContextModel.t): void,
  editItem(lineContext: LineContextModel.t): void,
  setShapeColor(shape: ShapeModel.t | ShapeModel.t[]): void,
  currentRenderedShapes: Array<ShapeModel.t>
};

const ItemRow = ({
  lineContext,
  deleteContext,
  editItem,
  setShapeColor,
  currentRenderedShapes
}: Props): React$Element<any> => {
  const { actions } = lineContext;
  const lineShapes = actions
    ? currentRenderedShapes.filter(shape => {
        const shapeIdExist = !!actions.find(action => {
          return action[0].lineId === shape.id && shape.type === LINE;
        });
        return shapeIdExist;
      })
    : [];

  return (
    <StyledRow
      onMouseEnter={() =>
        lineShapes.length > 0 ? setShapeColor(lineShapes) : null
      }
      onMouseLeave={() => setShapeColor({})}
    >
      <Column flex={col1Flex}>
        <Tag>{lineContext.type}</Tag>
      </Column>
      <Column flex={col2Flex}>
        <Table>
          <thead>
            <tr>
              <th>Line Id</th>
              <th>Action Type</th>
            </tr>
          </thead>
          <tbody>
            <Actions actions={lineContext.actions} />
          </tbody>
        </Table>
      </Column>
      <Column>
        {lineContext.type === WITHIN_LOCATION_MOVEMENT && (
          <Tag>{lineContext.withinLocationMovementKey}</Tag>
        )}
      </Column>

      {(lineContext.type === WITHIN_LOCATION_ENTRY ||
        lineContext.type === WITHIN_LOCATION_PASS_BY) &&
        lineContext.metadata && (
          <StyledColumn flex={col3Flex}>
            {lineContext.metadata.map((metadataItem, index) => {
              return (
                <StyledTag
                  key={`${index}-${metadataItem.key}-${metadataItem.value}`}
                >
                  Key: {metadataItem.key} | Value: {metadataItem.value}
                </StyledTag>
              );
            })}
          </StyledColumn>
        )}

      <Column flex={col4Flex} justifyContent={'flex-end'}>
        <Button intent="warning" onClick={() => editItem(lineContext)}>
          Edit
        </Button>
        <Button intent="danger" onClick={() => deleteContext(lineContext)}>
          Remove
        </Button>
      </Column>
    </StyledRow>
  );
};

export default ItemRow;
