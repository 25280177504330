import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { Navbar, Button, Alignment } from '@blueprintjs/core';
import auraVisionLogo from './../../commons/av_logo_white.png';
import { getAuth0Client } from '../../services/auth0';

const HeaderMain = styled(Navbar)`
  margin-bottom: 1em;
`;

const Logo = styled.img`
  height: 32px;
`;

const ButtonLink = ({ text, icon, to }) => {
  let match = useRouteMatch({
    path: to,
    exact: false
  });

  return (
    <Link to={to}>
      <Button large icon={icon} active={match} text={text} />
    </Link>
  );
};

class Header extends Component {
  state = {};

  async componentDidMount() {
    const auth0Client = getAuth0Client();
    const user = await auth0Client.getUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <HeaderMain className="bp3-dark">
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Logo src={auraVisionLogo} />
          </Navbar.Heading>
          <Navbar.Heading>
            <ButtonLink to="/cameras" icon="camera" text="Cameras" />
            <ButtonLink to="/users" icon="person" text="Users" />
            {/* <ButtonLink to="/devices" icon="cell-tower" text="Devices" /> */}
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Navbar.Heading>
            Logged in as {user && user.email} <Link to="/logout">Logout</Link>
          </Navbar.Heading>
        </Navbar.Group>
      </HeaderMain>
    );
  }
}
export default Header;
