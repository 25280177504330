import React, { useState, useEffect } from 'react';
import { recordings } from '../../../services/api';
import Navigation from '../../navigation';
import { Spinner } from '@blueprintjs/core';
import CamerasTable from '../../tables/camera-table';
import styled from 'styled-components';

const Row = styled.div`
  width: 95%;
  max-width: 1600px;
  margin: 1em auto;
  height: calc(calc(100vh - 50px) - 1em);
  position: relative;
`;

const AllRecordingsPage = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // onMount
    async function initialise() {
      try {
        const response = await recordings.list({
          include: 'locationDetails,organisationDetails,singleThumbnail',
        });
        if (response.length > 0) {
          const allOrgs = new Map();
          const allLocations = new Map();
          response.forEach((r) => {
            const { organisation: org = {}, location: loc = {} } = r;
            if (!!org && !allOrgs.has(org._id)) allOrgs.set(org._id, org);
            if (!!loc && !allLocations.has(loc._id))
              allLocations.set(loc._id, loc);
          });
          setData(
            response.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          );
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
    initialise();
  }, []);

  return (
    <>
      <Navigation />
      <Row>
        {isLoading && <Spinner />}
        {!isLoading && typeof data === 'undefined' && <h1>Loading failed</h1>}
        {!!data && <CamerasTable recordings={data} />}
      </Row>
    </>
  );
};
export default AllRecordingsPage;
