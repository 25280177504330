import { Toaster, Intent } from '@blueprintjs/core';

const toaster = Toaster.create();

const errorToast = args =>
  toaster.show({
    intent: Intent.WARNING,
    ...args
  });

const successToast = args =>
  toaster.show({
    intent: Intent.SUCCESS,
    ...args
  });

export { errorToast, successToast };
export default toaster;
