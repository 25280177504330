import React from 'react';
import { PopOverContainer } from './../../styles/templates';
import { InputGroup, Button } from '@blueprintjs/core';

export default function createNewShapeMenu(
  type,
  newShapeNameInput,
  setNewShapeNameInput,
  handleCreateNewShape
) {
  const isButtonDisabled = newShapeNameInput.length < 1;

  const enterKey = (event) => {
    if (event.key === 'Enter') {
      handleCreateNewShape(type);
    }
  };

  return (
    <PopOverContainer>
      <InputGroup
        type="text"
        leftIcon="edit"
        value={newShapeNameInput}
        placeholder={`${type} name`}
        name="name"
        autoFocus
        onChange={e => setNewShapeNameInput(e.target.value)}
        onKeyPress={enterKey}
      />
      <Button
        fill={true}
        disabled={isButtonDisabled}
        intent="primary"
        onClick={() => handleCreateNewShape(type)}
      >
        Create {type}
      </Button>
    </PopOverContainer>
  );
}
