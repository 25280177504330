import { Button } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CustomDataGrid from './custom-data-grid';

const rowHeight = 200;

const Thumbnail = ({ url, id }) =>
  url ? (
    <div
      style={{
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: rowHeight,
      }}
    >
      <Link to={`cameras/edit/${id}`}>
        <Button intent={'success'} icon={'edit'}>
          Edit
        </Button>
      </Link>
    </div>
  ) : (
    <Link to={`cameras/edit/${id}`}>
      <Button intent={'success'} icon={'edit'}>
        Navigate
      </Button>
    </Link>
  );

const CamerasTable = ({ recordings }) => {
  const [rows, setRows] = useState();
  const [filters, setFilters] = useState({});
  const [minHeight, setMinHeight] = useState(
    window.visualViewport.height - 100
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setMinHeight(window.visualViewport.height - 100);
  };

  const handleFilterChange = (filter) => (filters) => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };

  useEffect(() => {
    setRows(getRows(recordings, filters));
  }, [filters, recordings]);

  const columns = [
    {
      key: 'thumbnails',
      name: 'Thumbnail',
      locked: true,
      width: 250,
      formatter: (j) =>
        j.row?.thumbnails[0]?.url ? (
          <Thumbnail url={j.row.thumbnails[0].url} id={j.row.id} />
        ) : (
          'Not present'
        ),
    },
    {
      key: 'id',
      name: 'Recording ID',
      width: 200,
      filterable: true,
    },
    {
      key: 'organisation',
      name: 'Organisation Name',
      width: 200,
      filterable: true,
      formatter: (j) => j.row?.organisation?.name || 'Not present',
    },
    {
      key: 'location',
      name: 'Location Name',
      width: 200,
      filterable: true,
      formatter: (j) => j.row?.location?.name || 'Not present',
    },
    {
      key: 'name',
      name: 'Camera Name',
      width: 200,
      filterable: true,
      formatter: (j) => j.row?.name || 'Not present',
    },
    {
      key: 'createdAt',
      name: 'Created',
      width: 200,
      sortable: true,
    },
    {
      key: 'updatedAt',
      name: 'Last Updated',
      width: 200,
      sortable: true,
    },
  ];

  const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === 'NONE' ? initialRows : [...rows].sort(comparer);
  };

  function getRows(rows, filters) {
    const hasIdFilter = Object.keys(filters).includes('id');
    const hasOrgFilter = Object.keys(filters).includes('organisation');
    const hasLocFilter = Object.keys(filters).includes('location');
    const hasCamFilter = Object.keys(filters).includes('name');

    let newRows = rows;

    function filterById(rowArray, filterValue) {
      return rowArray.filter((r) => r?.id.toLowerCase().includes(filterValue));
    }
    function filterByOrganisation(rowArray, filterValue) {
      return rowArray.filter((r) =>
        r?.organisation?.name.toLowerCase().includes(filterValue)
      );
    }
    function filterByLocation(rowArray, filterValue) {
      return rowArray.filter((r) =>
        r?.location?.name.toLowerCase().includes(filterValue)
      );
    }
    function filterByCamera(rowArray, filterValue) {
      return rowArray.filter((r) =>
        r?.name.toLowerCase().includes(filterValue)
      );
    }

    if (!!newRows && !!filters && Object.keys(filters).length > 0) {
      if (hasIdFilter)
        newRows = filterById(newRows, filters.id.filterTerm.toLowerCase());
      if (hasOrgFilter)
        newRows = filterByOrganisation(
          newRows,
          filters.organisation.filterTerm.toLowerCase()
        );
      if (hasLocFilter)
        newRows = filterByLocation(
          newRows,
          filters.location.filterTerm.toLowerCase()
        );
      if (hasCamFilter)
        newRows = filterByCamera(
          newRows,
          filters.name.filterTerm.toLowerCase()
        );
      return newRows;
    } else {
      return rows;
    }
  }

  return !!recordings && recordings.length > 0 && !!rows ? (
    <CustomDataGrid
      columns={columns}
      rowGetter={(i) => rows[i]}
      rowsCount={rows.length}
      onGridSort={(sortColumn, sortDirection) =>
        setRows(sortRows(recordings, sortColumn, sortDirection))
      }
      enableRowSelect={null}
      enableCellSelect={true}
      rowHeight={rowHeight}
      headerRowHeight={50}
      onClearFilters={() => {
        setFilters({});
        setRows(recordings);
      }}
      onAddFilter={(filter) => setFilters(handleFilterChange(filter))}
      minHeight={minHeight}
    />
  ) : (
    'nothing to display'
  );
};

export default CamerasTable;
