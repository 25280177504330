import * as ACTIONS from '../constants/actions';

export const setOrgFilter = selectedOrg => async (dispatch, getState) => {
  const payload = { selectedOrg };
  dispatch({ type: ACTIONS.SELECT_ORGANISATION, payload });
};

export const setLocFilter = selectedLoc => async (dispatch, getState) => {
  const payload = { selectedLoc };
  dispatch({ type: ACTIONS.SELECT_LOCATION, payload });
};

export const clearFilters = () => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_FILTERS });
};
