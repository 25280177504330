// @flow

import type { t } from '../../models/line-context';
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Button, Classes, H3, Spinner } from '@blueprintjs/core';
import { LIGHT_GRAY_BLUE, NORMAL_GRAY_BLUE } from '../../styles/constants';
import { lineContext } from '../../services/api';
import { errorToast } from '../../utils/toaster';
import { transformLineContextInEditLineConfig } from '../../utils/lineContextHelpers';
import { WITHIN_LOCATION_MOVEMENT } from '../../constants/line-context';
import { Row, Column, col1Flex, col2Flex, col3Flex, col4Flex } from './table';
import EditingItemForm from './editing-item-form';
import ItemRow from './item-row';

import * as RecordingModel from '../../models/recording';
import * as ShapeModel from '../../models/shape';
import * as LineContextModel from '../../models/line-context';
import type { editLineConfigT } from './editing-item-form';

const sortLineContexts = (a, b) => {
  if (!a.createdAt) return -1;
  if (!b.createdAt) return -1;
  return a.createdAt < b.createdAt ? -1 : 1;
};

const H3Custom = styled(H3)`
  margin: 0 !important;
  color: ${NORMAL_GRAY_BLUE};
  font-size: 22px !important;
`;
const StyledSpinner = styled(Spinner)`
  margin: 0.5rem 0;
`;

type Props = {
  recording: RecordingModel.t,
  currentRenderedShapes: Array<ShapeModel.t>,
  setShapeColor(shape: ShapeModel.t | ShapeModel.t[]): void,
  keysAndValuesInUse?: {
    keys: string[],
    values: string[]
  },
  updateEditRecordingPageState(): void
};

type State = {
  lineContexts: LineContextModel.collectionT,
  loading: boolean,
  addingItem: boolean,
  editingExistedItem: editLineConfigT | null
};

export default class LineContexts extends Component<Props, State> {
  state: State = {
    lineContexts: [],
    addingItem: false,
    loading: false,
    editingExistedItem: null
  };

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState() {
    const { recording } = this.props;
    const initialState = {
      lineContexts: [],
      addingItem: false,
      loading: false
    };

    if (recording && recording.lineContexts) {
      initialState.lineContexts = recording.lineContexts;
    }

    this.setState(initialState);
  }

  beginAddingNewContext() {
    const { addingItem } = this.state;
    this.setState({
      addingItem: !addingItem
    });
  }

  stopAddingNewContext() {
    this.setState({
      addingItem: false
    });
  }

  validateContext(context: LineContextModel.t) {
    let doThrow = false;
    if (!context.type) {
      errorToast({ message: 'Missing a type' });
      doThrow = true;
    }
    if (
      context.type === WITHIN_LOCATION_MOVEMENT &&
      !context.withinLocationMovementKey
    ) {
      errorToast({ message: 'Missing a withinLocationMovementKey' });
      doThrow = true;
    }
    if (doThrow) throw new Error('Invalid form');
  }

  async saveNewContext(context: LineContextModel.t) {
    const { lineContexts, editingExistedItem } = this.state;

    this.setState({ loading: true });
    // do api call
    if (editingExistedItem) {
      try {
        this.validateContext(context);
        const response = await lineContext.update(
          context,
          this.props.recording.id
        );

        if (response) {
          const filteredLineContexts = lineContexts.reduce(
            (finalLineContexts, lContext) => {
              if (lContext.id !== context.id) {
                finalLineContexts.push(lContext);
              }
              return finalLineContexts;
            },
            []
          );

          this.setState(
            {
              addingItem: false,
              editingExistedItem: null,
              lineContexts: [...filteredLineContexts, response],
              loading: false
            },
            () => {
              this.props.updateEditRecordingPageState();
            }
          );
        } else {
          this.setState({
            addingItem: false,
            editingExistedItem: null,
            loading: false
          });
        }
      } catch (e) {
        this.setState({
          loading: false
        });
      }
    } else {
      try {
        this.validateContext(context);
        const response = await lineContext.create(
          context,
          this.props.recording.id
        );

        if (response) {
          this.setState({
            addingItem: false,
            lineContexts: [...lineContexts, response],
            loading: false
          });
        } else {
          this.setState(
            {
              addingItem: false,
              loading: false
            },
            () => {
              this.props.updateEditRecordingPageState();
            }
          );
        }
      } catch (e) {
        this.setState({
          loading: false
        });
      }
    }
  }

  async deleteContext(context: LineContextModel.t): Promise<null> | Promise<void> {
    const continueDelete = window.confirm(
      'Are you sure you want to delete this lineContext?'
    );
    if (continueDelete) {
      this.setState({ loading: true });
      // do api call
      try {
        if (context.id) {
          await lineContext.delete(context.id);
          // update the state
          this.setState(
            {
              lineContexts: [
                ...this.state.lineContexts.filter(ac => ac.id !== context.id)
              ],
              loading: false
            },
            () => {
              this.props.updateEditRecordingPageState();
            }
          );
        }
      } catch (e) {
        this.setState({
          loading: false
        });
      }
    }
  }

  handleBeingEditedLineContext: ((context: null | t) => void) = (context: LineContextModel.t | null) => {
    if (context) {
      const editLineConfig = transformLineContextInEditLineConfig(context);
      this.setState({
        editingExistedItem: editLineConfig
      });
    } else {
      this.setState({
        editingExistedItem: context
      });
    }
  };

  render(): React$Element<React$FragmentType> {
    const {
      currentRenderedShapes,
      recording,
      setShapeColor,
      keysAndValuesInUse,
    } = this.props;
    const {
      lineContexts,
      addingItem,
      loading,
      editingExistedItem
    } = this.state;

    const editingItemFormProps = {
      keysAndValuesInUse,
      // $FlowFixMe[method-unbinding]
      cancelEditing: this.stopAddingNewContext.bind(this),
      currentRenderedShapes,
      handleBeingEditedLineContext: this.handleBeingEditedLineContext.bind(
        this
      ),
      setShapeColor,
      // $FlowFixMe[method-unbinding]
      saveItem: this.saveNewContext.bind(this),
      loading,
      recording,
      newItem: editingExistedItem,
      lineContexts
    };

    return (
      <Fragment>
        <Row padding="1.5em 0">
          <Column flex={2}>
            <H3Custom>Line Contexts</H3Custom>
          </Column>
          <Column flex={7}>{lineContexts.length} found</Column>
          <Column flex={1}>
            <Button
              icon="plus"
              fill={true}
              // $FlowFixMe[method-unbinding]
              onClick={this.beginAddingNewContext.bind(this)}
            >
              Add New
            </Button>
          </Column>
        </Row>
        <Row backgroundColor={LIGHT_GRAY_BLUE}>
          <Column flex={col1Flex}>Type</Column>
          <Column flex={col2Flex}>Actions</Column>
          <Column flex={col3Flex}></Column>
          <Column flex={col4Flex} justifyContent="flex-end">
            Buttons
          </Column>
        </Row>
        {lineContexts.sort(sortLineContexts).map(lineContext => {
          return editingExistedItem &&
            lineContext.id &&
            editingExistedItem.id === lineContext.id ? (
            <EditingItemForm
              {...editingItemFormProps}
              key={`editing: ${lineContext.id}`}
              newItem={editingExistedItem}
            />
          ) : loading ? (
              <StyledSpinner key={lineContext.id} className={Classes.SMALL} />
            ) : (
              <ItemRow
                currentRenderedShapes={currentRenderedShapes}
                setShapeColor={setShapeColor}
                key={lineContext.id}
                // $FlowFixMe[method-unbinding]
                deleteContext={this.deleteContext.bind(this)}
                lineContext={lineContext}
                editItem={this.handleBeingEditedLineContext.bind(this)}
             />
            )}
          )
        }
        {addingItem && (
          <Fragment>
            <h4>Add new item</h4>
            <EditingItemForm {...editingItemFormProps} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}
