import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { getAuth0Client } from '../../../services/auth0';
import PreInitLoading from '../pre-init-loading';

class LoginPage extends Component {
  state = { redirectToHome: false };

  async componentDidMount() {
    const auth0 = getAuth0Client();
    const isAuthenticated = await auth0.isAuthenticated();

    if (isAuthenticated) {
      this.setState({ redirectToHome: true });
    } else {
      await auth0.loginWithRedirect({
        redirect_uri: window.location.origin
      });
    }
  }

  render() {
    if (this.state.redirectToHome) {
      return <Redirect to={'/'} />;
    }

    return <PreInitLoading />;
  }
}

export default LoginPage;
