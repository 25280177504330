import React from 'react';
import { PopOverContainer } from './../../styles/templates';
import { H4, NumericInput } from '@blueprintjs/core';

export default function ChangeVarLineMenu({ shape, saveNewLineVar }) {
  return (
    <PopOverContainer>
      <H4>Change Var</H4>
      <NumericInput
        value={shape.points.var}
        min={0}
        onValueChange={valueAsNumber => saveNewLineVar(shape, valueAsNumber)}
      />
    </PopOverContainer>
  );
}
