import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Intent, Icon } from '@blueprintjs/core';
import { LIGHT_GRAY_BLUE } from '../../../styles/constants';

const Title = styled.h3`
  margin-top: 1em;
`;
const Wrapper = styled.section`
  padding: 2em;
  background: ${LIGHT_GRAY_BLUE};
  width: 100%;
  text-align: center;
  height: 100vh;
`;

const Page404 = () => (
  <Wrapper>
    <Icon icon="warning-sign" />
    <Title>Page not found</Title>
    <Link to={'/'}>
      <Button intent={Intent.PRIMARY}>Home</Button>
    </Link>
  </Wrapper>
);

export default Page404;
