// @flow

import { API_BASE } from '../config/vars';
import { call } from './http';

import * as AreaContextModel from '../models/area-context';
import * as RecordingModel from '../models/recording';
import * as LineContextModel from '../models/line-context';

// Quick fix to avoid making multiple calls to the same endpoint with the same
// parameters
// TODO: remove or improve this
const cache = {
  recordings: new Map(),
};

const formatAreaContextBody = (
  areaContext: AreaContextModel.t,
  recordingId: string
): string => {
  return JSON.stringify({
    type: areaContext.type,
    areaId: areaContext.areaId,
    taxonomy: areaContext.taxonomy,
    recording: recordingId
  });
};

const formatLineContextBody = (
  lineContext: LineContextModel.t,
  recordingId: string
): string => {
  return JSON.stringify({
    type: lineContext.type,
    actions: lineContext.actions,
    withinLocationMovementKey: lineContext.withinLocationMovementKey,
    recording: recordingId,
    metadata: lineContext.metadata
  });
};

export const areaContext = {
  create: (
    newAreaContext: AreaContextModel.t,
    recordingId: string
  ): Promise<Object> => {
    const body = formatAreaContextBody(newAreaContext, recordingId);
    return call({
      path: `${API_BASE}/v1/areaContext/create`,
      fetchOptions: { method: 'POST', body }
    });
  },
  update: (
    updatedAreaContext: AreaContextModel.t,
    recordingId: string
  ): Promise<Object> => {
    const body = formatAreaContextBody(updatedAreaContext, recordingId);
    return call({
      path: `${API_BASE}/v1/areaContext/${updatedAreaContext.id}/update`,
      fetchOptions: { method: 'POST', body }
    });
  },
  delete: (deletedAreaContextId: string): Promise<Object> =>
    call({
      path: `${API_BASE}/v1/areaContext/${deletedAreaContextId}/delete`,
      fetchOptions: { method: 'DELETE' },
      responseExpected: false
    })
};

export const lineContext = {
  create: (
    newLineContext: LineContextModel.t,
    recordingId: string
  ): Promise<Object> => {
    const body = formatLineContextBody(newLineContext, recordingId);
    return call({
      path: `${API_BASE}/v1/lineContext/create`,
      fetchOptions: { method: 'POST', body }
    });
  },
  update: (
    updatedLineContext: LineContextModel.t,
    recordingId: string
  ): ?Promise<Object> => {
    const body = formatLineContextBody(updatedLineContext, recordingId);
    if (!updatedLineContext.id) {
      return null;
    }
    return call({
      path: `${API_BASE}/v1/lineContext/${updatedLineContext.id}/update`,
      fetchOptions: { method: 'POST', body }
    });
  },
  delete: (deletedAreaContextId: string): Promise<Object> =>
    call({
      path: `${API_BASE}/v1/lineContext/${deletedAreaContextId}/delete`,
      fetchOptions: { method: 'DELETE' },
      responseExpected: false
    })
};

export const recordings = {
  get: (recId: string): Promise<Object> =>
    call({
      path: `${API_BASE}/v1/recording/${recId}?include=locationDetails,organisationDetails,statElems,singleThumbnail,lineContexts,areaContexts`,
      fetchOptions: { method: 'GET' }
    }),
  list: ({
    withinCameraServers,
    withinOrganisations,
    include
  }: {
    withinCameraServers: string,
    withinOrganisations: string,
    include: string
  }): Promise<Object> => {
    const body = {};
    if (include) {
      body.include = include;
    }
    if (withinCameraServers) {
      body.withinCameraServers = withinCameraServers;
    }
    if (withinOrganisations) {
      body.withinOrganisations = withinOrganisations;
    }

    let result = {};
    if (cache.recordings.get(JSON.stringify((body)))) {
      return Promise.resolve(cache.recordings.get(JSON.stringify((body))));
    } else {
      result = call({
        path: `${API_BASE}/v1/recording/list`,
        fetchOptions: { method: 'POST', body: JSON.stringify(body) }
      });
      cache.recordings.set(JSON.stringify(body), result);
    }
    return result;
  }
};

export const cameraServers = {
  list: (
    status: string,
    hardwareClass: string,
    orgs: string[],
    locations: string[]
  ): Promise<Object> => {
    const body = {
      include: 'organisationDetails,locationDetailsWithoutTransactions',
      status,
      hardwareClass,
      withinOrganisations: orgs ? orgs.join(',') : undefined,
      withinLocations: locations ? locations.join(',') : undefined
    };

    return call({
      path: `${API_BASE}/v1/cameraServer/list`,
      fetchOptions: { method: 'POST', body: JSON.stringify(body) }
    });
  }
};

export const organisations = {
  listAll: (): Promise<Object> =>
    call({
      path: `${API_BASE}/v1/organisation`,
      fetchOptions: { method: 'GET' }
    })
};

export const locations = {
  listAll: (): Promise<Object> =>
    call({
      path: `${API_BASE}/v1/location/list`,
      fetchOptions: { method: 'POST' }
    })
};

export const statElems = {
  create: (recording: RecordingModel.t, config: Object): Promise<Object> =>
    call({
      path: `${API_BASE}/v1/statElems/create`,
      fetchOptions: {
        method: 'POST',
        body: JSON.stringify({ recording, config })
      }
    })
};
