// @flow

import { errorToast } from '../utils/toaster';
import { getAuth0Client } from './auth0';

type callArgs = {
  path: string,
  fetchOptions?: Object,
  otherOptions?: Object,
  responseExpected?: boolean,
  responseMethod?: 'json' | 'blob'
};

const call = async ({
  path,
  fetchOptions,
  otherOptions = {},
  responseExpected = true,
  responseMethod = 'json'
}: callArgs): Promise<any> => {
  const fetchOpts = {
    ...fetchOptions,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: undefined
    }
  };

  const auth0Client = getAuth0Client();
  const token = await auth0Client.getTokenSilently();

  if (token) {
    fetchOpts.headers.Authorization = `Bearer ${token}`;
  }

  const res = await fetch((path: string), (fetchOpts: Object));
  if (res.ok && responseExpected) {
    // $FlowFixMe Flow doesnt know if a response can have a json or blob method
    return await res[responseMethod]();
  } else if (res.ok && !responseExpected) {
    return true;
  }

  // if response is 401 show a toast prompting the user to log back in
  if (res.status === 401 && !otherOptions.disable401Toast) {
    errorToast({ message: 'Got a 401 from API' });
    return false;
  }

  if (!otherOptions.disableErrorToasts) {
    errorToast({
      message: `Got an error (code ${res.statusText}) from the API. Check logs`
    });
  }
  console.error('Error response', res);
  console.error('Error response body', await res.json());
  throw new Error(res.status);
};

export { call };
