import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FilteringOptionsActions from '../actions/filtering-options';
import AllRecordingsPage from '../components/pages/all-recordings';

function mapStateToProps(state) {
  return {
    selectedOrg: state.filteringOptions.selectedOrg,
    selectedLoc: state.filteringOptions.selectedLoc
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearFilters: FilteringOptionsActions.clearFilters
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(props => (
  <AllRecordingsPage {...props} />
));
