import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Callout,
  HTMLSelect,
  FormGroup,
  Button
} from '@blueprintjs/core';
import styled from 'styled-components';
import ReactDataGrid from 'react-data-grid';

import { capabilityModelColumnsNoActions } from '../../pages/users/capability-model-columns';
import * as AuthAPI from '../../../services/auth-api';
import { genericSorter } from '../../../utils/dataGridTableSorting';

const DataWrapper = styled.form`
  width: 95%;
  margin: 2em auto;
`;
const CalloutWrapper = styled.form`
  width: 95%;
  margin: 0 auto 2em auto;
`;

export default ({ isOpen, onClose, selectedRoleId, title, allOrgs }) => {
  const [capabilitiesForRole, setCapabilitiesForRole] = useState([]);
  const [allCapabilities, setAllCapabilities] = useState([]);
  const [capabilityToAddToRole, setCapabilityToAddToRole] = useState(null);
  const [tableHeight, setTableHeight] = useState(600);
  const [addCapabilityResponse, setAddCapabilityResponse] = useState({});

  const columns = capabilityModelColumnsNoActions;

  useEffect(() => {
    if (isOpen) {
      fetchCapabilities();
      setAddCapabilityResponse({});
    } else {
      setCapabilitiesForRole([]);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  async function fetchCapabilities() {
    try {
      const {
        data: capabilitiesForRole
      } = await AuthAPI.adminRoles_capabilities.list(selectedRoleId, allOrgs);
      const { data: allCapabilities } = await AuthAPI.capabilities.list();

      setCapabilitiesForRole(capabilitiesForRole);
      setAllCapabilities(allCapabilities);
    } catch (e) {
      console.error(e);
    }
  }

  const addCapabilityToRole = async () => {
    try {
      setAddCapabilityResponse({ type: 'info', text: 'Loading...' });
      await AuthAPI.adminRoles_capabilities.create(
        selectedRoleId,
        capabilityToAddToRole
      );
      setAddCapabilityResponse({
        type: 'success',
        text: 'Capability added to role'
      });
      fetchCapabilities();
    } catch (e) {
      setAddCapabilityResponse({
        type: 'danger',
        text: 'Could not add capability to role'
      });
    }
  };

  const sortTable = (sortedData) => {
    setCapabilitiesForRole(sortedData);
    // HACK - forces the table ro rerender the new sorted table data
    tableHeight === 600 ? setTableHeight(601) : setTableHeight(600);
  };

  return (
    <Drawer
      size={Drawer.SIZE_LARGE}
      icon="person"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DataWrapper>
        <ReactDataGrid
          columns={columns}
          rowGetter={(i) => capabilitiesForRole[i]}
          rowsCount={capabilitiesForRole.length}
          minHeight={tableHeight}
          onGridSort={(column, sortDir) => {
            sortTable(genericSorter(capabilitiesForRole, column, sortDir));
          }}
          enableCellSelect={true}
        />
      </DataWrapper>
      <CalloutWrapper>
        <Callout title="Assign a new capability to this role">
          <FormGroup>
            <HTMLSelect
              onChange={(e) => setCapabilityToAddToRole(e.target.value)}
              value={capabilityToAddToRole}
              options={
                allCapabilities.length === 0
                  ? [{ label: 'Loading...', value: null }]
                  : allCapabilities.map((cap) => ({
                      label: `${cap.name} (id: ${cap.id})`,
                      value: cap.id
                    }))
              }
            />
            <Button text="Add" intent="primary" onClick={addCapabilityToRole} />
          </FormGroup>
        </Callout>
        {addCapabilityResponse.type && (
          <Callout intent={addCapabilityResponse.type}>
            {addCapabilityResponse.text}
          </Callout>
        )}
      </CalloutWrapper>
    </Drawer>
  );
};
