export default selectedOrgName => ({
  'Analytics data viewer': {
    checkedByDefaultForNewUserInvite: true,
    deleteDisabled: true,
    createDisabled: false,
    label: 'Analytics data viewer',
    description:
      'Can access this organisations data on the dashboard (required)'
  },
  'Client Admin - Auth Service Administration': {
    checkedByDefaultForNewUserInvite: false,
    deleteDisabled: false,
    createDisabled: selectedOrgName === 'Demo Company' ? true : false,
    label: 'User Admin',
    description:
      'Can view, add, edit and delete users within this organisation (disabled for Demo Company users)'
  },
  'Data Viewer - Auth Service Access': {
    checkedByDefaultForNewUserInvite: true,
    deleteDisabled: true,
    createDisabled: false,
    label: 'Basic user access',
    description: 'Can view and edit their own account (required)'
  },
  'Analytics data viewer - manage reports': {
    checkedByDefaultForNewUserInvite: false,
    deleteDisabled: false,
    createDisabled: selectedOrgName === 'Demo Company' ? true : false,
    label: 'Manage reports',
    description:
      'Can create, edit and remove dashboard reports and email report schedules (disabled for Demo Company users)'
  },
  'Client Admin - manage locations': {
    checkedByDefaultForNewUserInvite: false,
    deleteDisabled: false,
    createDisabled: selectedOrgName === 'Demo Company' ? true : false,
    label: 'Manage locations',
    description:
      'Can create, edit and remove locations (disabled for Demo Company users)'
  },
  'Client Admin - camera and device set up': {
    checkedByDefaultForNewUserInvite: false,
    deleteDisabled: false,
    createDisabled: selectedOrgName === 'Demo Company' ? true : false,
    label: 'Access to the cameras tool',
    description:
      'Can log in to the cameras tool and edit lines and areas'
  }
});
