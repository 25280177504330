import React, { useState, Fragment } from 'react';
import {
  H6,
  Icon,
  Divider,
  Collapse,
  MenuItem,
  Menu,
  Popover,
  Position,
} from '@blueprintjs/core';
import { NORMAL_GRAY_BLUE } from '../../styles/constants';
import { LINE, AREA } from '../../constants/shapes';
import styled from 'styled-components';
import PopOverChangeNameMenu from '../pop-over-change-name-menu';
import ChangeVarLineMenu from '../change-var-line-menu';

const TitleShape = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
const ShapeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
`;
const H6Custom = styled(H6)`
  margin-bottom: 0 !important;
  margin: 0.5em 0 !important;
  margin-left: 1em !important;
`;
const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
const VisibilityIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

const MenuListElement = ({
  shape,
  setShapeColor,
  handleCurrentMenuOpen,
  currentMenuOpen,
  deleteShape,
  addNewPoint,
  deletePoint,
  changeShapeName,
  changeLineArrowDirection,
  copyShape,
  changeAreaIgnoreType,
  saveNewLineVar,
  toggleShapeVisibility,
}) => {
  const [changeShapeNameInput, setChangeShapeNameInput] = useState('');

  const handleNameChange = (shape) => {
    changeShapeName(shape, changeShapeNameInput);
    setChangeShapeNameInput('');
  };

  return (
    <Fragment>
      <div
        onMouseEnter={() => setShapeColor(shape)}
        onMouseLeave={() => setShapeColor({})}
      >
        <TitleShape onClick={() => handleCurrentMenuOpen(shape)}>
          <VisibilityIcon
            icon={shape.visible ? 'eye-open' : 'eye-off'}
            color={shape.visible ? null : NORMAL_GRAY_BLUE}
            onClick={(e) => {
              e.stopPropagation();
              toggleShapeVisibility(shape.id);
            }}
          />
          <ShapeHeader>
            <Icon
              icon={shape.type === LINE ? 'flow-linear' : 'polygon-filter'}
              color={NORMAL_GRAY_BLUE}
            />
            <H6Custom>{shape.id}</H6Custom>
          </ShapeHeader>
          <StyledIcon icon="caret-down" color={NORMAL_GRAY_BLUE} />
        </TitleShape>
        <Divider />
        <Collapse
          isOpen={
            shape.id === currentMenuOpen.id &&
            shape.type === currentMenuOpen.type
          }
        >
          <Menu large={true} className="menu-edit-line__SubMenu">
            <Popover
              popoverClassName="bp3-popover-content-sizing"
              position={Position.LEFT}
              content={
                <PopOverChangeNameMenu
                  shape={shape}
                  changeShapeNameInput={changeShapeNameInput}
                  handleChangeShapeNameInput={setChangeShapeNameInput}
                  handleNameChange={handleNameChange}
                />
              }
              target={
                <MenuItem text={`Change ${shape.type} name`} icon="edit" />
              }
            />

            {shape.type === AREA && [
              <MenuItem
                key={`${shape.id}-1`}
                onClick={() => addNewPoint(shape)}
                text="Add new Point"
                icon="new-link"
              />,
              <MenuItem
                key={`${shape.id}-2`}
                onClick={() => deletePoint(shape)}
                text="Delete Point"
                icon="delete"
              />,
              <MenuItem
                key={`${shape.id}-3`}
                onClick={() => changeAreaIgnoreType(shape)}
                text={`Make area: ${shape.isIgnoreArea ? 'Normal' : 'Ignore'}`}
                icon="exchange"
              />,
            ]}
            {shape.type === LINE && [
              <MenuItem
                onClick={() => changeLineArrowDirection(shape)}
                text="Invert Arrow Direction"
                icon="circle-arrow-left"
                key={`${shape.id}-line-1`}
              />,
              <Popover
                popoverClassName="bp3-popover-content-sizing"
                position={Position.LEFT}
                key={`${shape.id}-line-2`}
                content={
                  <ChangeVarLineMenu
                    shape={shape}
                    saveNewLineVar={saveNewLineVar}
                  />
                }
                target={
                  <MenuItem
                    text={`Change current var: ${shape.points.var}`}
                    icon="layout-linear"
                  />
                }
              />,
            ]}
            <MenuItem
              text={`Duplicate ${shape.type}`}
              icon="duplicate"
              intent="success"
              onClick={() => copyShape(shape)}
            />
            <MenuItem
              text={`Delete ${shape.type}`}
              icon="eraser"
              intent="danger"
              onClick={() => deleteShape(shape)}
            />
          </Menu>
        </Collapse>
      </div>
    </Fragment>
  );
};

export default MenuListElement;
