import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Callout } from '@blueprintjs/core';

const ChangesNotSavedContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding-top: 1em;
`;

const WarningCallout = styled(Callout)`
  margin-top: 0;
`;

const ButtonRight = styled(Button)`
  &&& {
    position: relative;
    margin-top: 1em;
    left: 0;
  }
`;

export default function EditButton({
  changesDoneButNotSaved,
  handleSaveChanges
}) {
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    await handleSaveChanges();
    setLoading(false);
  };

  return changesDoneButNotSaved ? (
    <ChangesNotSavedContainer>
      <WarningCallout
        title="You have unsaved changes"
        intent="warning"
        icon="floppy-disk"
      />
      <ButtonRight
        intent="primary"
        loading={loading}
        onClick={() => save()}
        text="Save Changes"
      />
    </ChangesNotSavedContainer>
  ) : null;
}
