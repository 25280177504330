import React, { useState, useEffect } from 'react';
import { Drawer } from '@blueprintjs/core';
import styled from 'styled-components';
import ReactDataGrid from 'react-data-grid';

import * as AuthAPI from '../../../services/auth-api';
import { genericSorter } from '../../../utils/dataGridTableSorting';
import { userModelColumnsNoActionsNoUsersOrgs } from '../../pages/users/user-model-columns';

const DataWrapper = styled.form`
  width: 90%;
  margin: 2em auto;
`;

export default ({
  isOpen,
  onClose,
  selectedRoleId,
  selectedRoleIsAdmin,
  title,
  allOrgs,
}) => {
  const [users, setUsers] = useState([]);
  const [tableHeight, setTableHeight] = useState(600);

  useEffect(() => {
    async function fetchData() {
      const getOrgName = (orgId) => {
        const org = allOrgs.find((o) => o.id === orgId);
        if (!org || !org.name) return 'Name not found';
        return org.name;
      };
      try {
        const apiGroup = selectedRoleIsAdmin
          ? 'adminRoles_users'
          : 'roles_users';
        const { data } = await AuthAPI[apiGroup].list(selectedRoleId, allOrgs);

        const usersWithOrgNames = data.map((user) => ({
          ...user,
          primaryOrgName: getOrgName(user.primaryOrgId),
          ownerOrgName: getOrgName(user.ownerOrgId),
        }));

        setUsers(usersWithOrgNames);
      } catch (e) {
        console.error(e);
      }
    }

    if (isOpen) {
      fetchData();
    } else {
      setUsers([]);
    }
  }, [isOpen, allOrgs, selectedRoleId, selectedRoleIsAdmin]);

  const sortTable = (sortedData) => {
    setUsers(sortedData);
    // HACK - forces the table ro rerender the new sorted table data
    tableHeight === 600 ? setTableHeight(601) : setTableHeight(600);
  };

  return (
    <Drawer
      size={Drawer.SIZE_LARGE}
      icon="person"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DataWrapper>
        <ReactDataGrid
          columns={userModelColumnsNoActionsNoUsersOrgs}
          rowGetter={(i) => users[i]}
          rowsCount={users.length}
          minHeight={tableHeight}
          enableCellSelect={true}
          onGridSort={(column, sortDir) => {
            sortTable(genericSorter(users, column, sortDir));
          }}
        />
      </DataWrapper>
    </Drawer>
  );
};
