export function doesWordExistInSentence(word, sentence) {
  if (typeof sentence !== 'string' || typeof word !== 'string') return false;
  let regexp = new RegExp(word, 'ig');
  return regexp.test(sentence);
}

export function foundAtLeastOneMatch(word, recordings) {
  const found = recordings.find(shape => checkIfWordIsFound(word, shape));
  return found !== undefined;
}

export function checkIfWordIsFound(searchInput, shape) {
  return (
    doesWordExistInSentence(searchInput, shape.name) ||
    (shape.organisation && doesWordExistInSentence(searchInput, shape.organisation.name)) ||
    (shape.location &&
      doesWordExistInSentence(searchInput, shape.location.name))
  );
}
