// @flow

import React from 'react';
import styled from 'styled-components';
import { Popover, Button, MenuItem, Menu } from '@blueprintjs/core';
import { LINE } from '../../constants/shapes';

import type { editingConfigActionT } from './editing-item-form';
import * as ShapeModel from '../../models/shape';

export const StyledMenu: any = styled(Menu)`
  max-height: 200px;
  overflow-y: scroll;
`;

type Props = {
  action: editingConfigActionT,
  index: number,
  currentRenderedShapes: Array<ShapeModel.t>,
  setShapeColor(shape: ShapeModel.t): void,
  handleSelectLineId(id: string, index: number): void,
};

const LineIdSelector = ({
  currentRenderedShapes,
  action,
  setShapeColor,
  handleSelectLineId,
  index,
}: Props): React$Element<any> => {
  return (
    <Popover>
      <Button
        text={action.lineId ? action.lineId : 'Select'}
        rightIcon="caret-down"
      />
      <StyledMenu>
        {currentRenderedShapes
          .filter((shape) => shape.type === LINE)
          .map((line) => (
            <MenuItem
              key={line.id}
              text={line.id}
              onMouseEnter={() => setShapeColor(line)}
              onMouseLeave={() => setShapeColor({})}
              onClick={(e) => handleSelectLineId(line.id, index)}
            />
          ))}
      </StyledMenu>
    </Popover>
  );
};

export default LineIdSelector;
