import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Button, Classes, H3, Spinner } from '@blueprintjs/core';
import { uniq } from 'lodash';
import { LIGHT_GRAY_BLUE, NORMAL_GRAY_BLUE } from '../../styles/constants';
import { areaContext } from '../../services/api';
import { errorToast } from '../../utils/toaster';

import { Row, Column, col1Flex, col2Flex, col3Flex, col4Flex } from './table';
import EditingItemForm from './editing-item-form';
import ItemRow from './item-row';

const H3Custom = styled(H3)`
  margin: 0 !important;
  color: ${NORMAL_GRAY_BLUE};
  font-size: 22px !important;
`;
const StyledSpinner = styled(Spinner)`
  margin: 0.5rem 0;
`;
export default class ShapesContexts extends Component {
  state = {
    areaContexts: [],
    currentTaxonomies: [],
    editingItem: null,
    newItem: null,
    loading: false,
    taxonomiesInUse: []
  };

  componentDidMount() {
    const { recording, taxonomiesInUse } = this.props;
    const initialState = { taxonomiesInUse };
    if (recording && recording.areaContexts) {
      initialState.areaContexts = recording.areaContexts;
    }
    this.setState(initialState);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.taxonomiesInUse || !prevProps.taxonomiesInUse.length) &&
      this.props.taxonomiesInUse &&
      this.props.taxonomiesInUse.length
    ) {
      this.setState({ taxonomiesInUse: this.props.taxonomiesInUse });
    }
  }

  beginEditingContext(contextBeingEdited) {
    if (this.state.editingItem) {
      const continueEdit = window.confirm('Cancel editing the other item?');
      if (continueEdit) this.editContext(contextBeingEdited);
    } else {
      this.editContext(contextBeingEdited);
    }
  }

  editContext(contextBeingEdited) {
    this.setState({
      editingItem: contextBeingEdited
    });
  }

  stopEditingContext() {
    this.setState({
      editingItem: null
    });
  }

  beginAddingNewContext() {
    this.setState({
      newItem: {
        type: 'taxonomy'
      }
    });
  }

  editNewContext(contextBeingAdded) {
    this.setState({
      newItem: contextBeingAdded
    });
  }

  stopAddingNewContext() {
    this.setState({
      newItem: null
    });
  }

  validateContext(context) {
    let doThrow = false;
    if (!context.type) {
      errorToast({ message: 'Missing a type' });
      doThrow = true;
    }
    if (!context.areaId) {
      errorToast({ message: 'Missing an areaId' });
      doThrow = true;
    }
    if (context.type === 'taxonomy' && !context.taxonomy) {
      errorToast({ message: 'Missing a taxonomy' });
      doThrow = true;
    }
    if (doThrow) throw new Error('Invalid form');
  }

  async saveNewContext(context) {
    this.setState({ loading: true });
    // do api call
    try {
      this.validateContext(context);
      const response = await areaContext.create(
        context,
        this.props.recording.id
      );

      if (response && response.id) {
        const newContext = {
          ...context,
          id: response.id
        };
        // update the state
        this.setState(
          {
            newItem: null,
            areaContexts: [...this.state.areaContexts, newContext],
            loading: false,
            taxonomiesInUse: uniq([
              ...this.state.taxonomiesInUse,
              context.taxonomy
            ])
          },
          () => {
            this.props.updateEditRecordingPageState();
          }
        );
      } else {
        errorToast({ message: 'Your area context wasn\'t saved' });
      }
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  }

  async saveExistingContext(context) {
    this.setState({ loading: true });
    // do api call
    try {
      this.validateContext(context);
      await areaContext.update(context, this.props.recording.id);
      // update the state
      this.setState(
        {
          editingItem: null,
          areaContexts: [
            ...this.state.areaContexts.filter(ac => ac.id !== context.id),
            context
          ],
          loading: false,
          taxonomiesInUse: uniq([
            ...this.state.taxonomiesInUse,
            context.taxonomy
          ])
        },
        () => {
          this.props.updateEditRecordingPageState();
        }
      );
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  }

  async deleteContext(context) {
    const continueDelete = window.confirm(
      'Are you sure you want to delete this areaContext?'
    );
    if (continueDelete) {
      this.setState({ loading: true });
      // do api call
      try {
        await areaContext.delete(context.id);
        // update the state
        this.setState(
          {
            areaContexts: [
              ...this.state.areaContexts.filter(ac => ac.id !== context.id)
            ],
            loading: false
          },
          () => {
            this.props.updateEditRecordingPageState();
          }
        );
      } catch (e) {
        this.setState({
          loading: false
        });
      }
    }
  }

  render() {
    const { currentRenderedShapes, setShapeColor, loadingTaxonomies } = this.props;
    const {
      areaContexts,
      taxonomiesInUse,
      editingItem,
      newItem,
      loading
    } = this.state;

    return (
      <Fragment>
        <Row padding={'1.5em 0'}>
          <Column flex={2}>
            <H3Custom>Area Contexts</H3Custom>
          </Column>
          <Column flex={7}>{areaContexts.length} found</Column>
          <Column flex={1}>
            <Button
              icon={'plus'}
              fill={true}
              onClick={this.beginAddingNewContext.bind(this)}
            >
              Add New
            </Button>
          </Column>
        </Row>
        <Row backgroundColor={LIGHT_GRAY_BLUE}>
          <Column flex={col1Flex}>Type</Column>
          <Column flex={col2Flex}>Area Id</Column>
          <Column flex={col3Flex}>Taxonomy</Column>
          <Column flex={col4Flex} justifyContent={'flex-end'}>
            Actions
          </Column>
        </Row>
        {areaContexts
          .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
          .map(areaContext => {
            if (editingItem && editingItem.id === areaContext.id) {
              return (
                <EditingItemForm
                  key={areaContext.id}
                  item={editingItem}
                  editItem={this.editContext.bind(this)}
                  cancelEditing={this.stopEditingContext.bind(this)}
                  currentRenderedShapes={currentRenderedShapes}
                  taxonomiesInUse={taxonomiesInUse}
                  setShapeColor={setShapeColor}
                  saveItem={this.saveExistingContext.bind(this)}
                  loading={loading}
                  loadingTaxonomies={loadingTaxonomies}
                />
              );
            } else {
              return loading ? (<StyledSpinner key={`${areaContext.areaId}${areaContext.taxonomy}_${areaContext.id}`} className={Classes.SMALL} />) : (
                <ItemRow
                  key={`${areaContext.areaId}${areaContext.taxonomy}_${areaContext.id}`}
                  beginEditingItem={this.beginEditingContext.bind(this)}
                  deleteContext={this.deleteContext.bind(this)}
                  areaContext={areaContext}
                  setShapeColor={setShapeColor}
                />
              );
            }
          })}
        {newItem && (
          <Fragment>
            <h4>Add new item</h4>
            <EditingItemForm
              item={newItem}
              editItem={this.editNewContext.bind(this)}
              cancelEditing={this.stopAddingNewContext.bind(this)}
              currentRenderedShapes={currentRenderedShapes}
              taxonomiesInUse={taxonomiesInUse}
              setShapeColor={setShapeColor}
              saveItem={this.saveNewContext.bind(this)}
              loading={loading}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}
