import React from 'react';
import { Checkbox, Tag } from '@blueprintjs/core';

const commonConf = {
  resizable: true,
  editable: true,
  sortable: true
};
export const capabilityModelColumns = [
  { key: 'id', name: 'Id', ...commonConf, width: 50 },
  {
    key: 'resource',
    name: 'Resource',
    ...commonConf,
    width: 175,
    formatter: (row) => <Tag intent="primary">{row.value}</Tag>
  },
  {
    key: 'canCreate',
    name: 'Create',
    resizable: true,
    width: 65,
    formatter: (row) => <Checkbox checked={!!row.value} />
  },
  {
    key: 'canRead',
    name: 'Read',
    resizable: true,
    width: 65,
    formatter: (row) => <Checkbox checked={!!row.value} />
  },
  {
    key: 'canUpdate',
    name: 'Update',
    resizable: true,
    width: 65,
    formatter: (row) => <Checkbox checked={!!row.value} />
  },
  {
    key: 'canDelete',
    name: 'Delete',
    resizable: true,
    width: 65,
    formatter: (row) => <Checkbox checked={!!row.value} />
  },
  { key: 'visibility', name: 'Visibility type', ...commonConf, width: 120 },
  { key: 'name', name: 'Name', ...commonConf },
  {
    key: 'auraAdminOnly',
    name: 'Internal use only',
    ...commonConf,
    width: 130,
    formatter: (row) => <Checkbox checked={!!row.value} />
  },
  { key: 'createdAt', name: 'Created', ...commonConf, width: 220 },
  { key: 'updatedAt', name: 'Last updated', ...commonConf, width: 220 }
  // {
  //   key: 'actions',
  //   name: 'Actions',
  //   resizable: true,
  //   editable: true,
  //   width: 200
  // }
];

export const capabilityModelColumnsNoActions = capabilityModelColumns.filter(
  (col) => col.key !== 'actions'
);
