export const genericSorter = (data, column, sortDir) => {
  return data.sort((a, b) => {
    if (a[column] > b[column]) {
      if (sortDir === 'DESC') return -1;
      if (sortDir === 'ASC') return 1;
      return 0;
    } else {
      if (sortDir === 'DESC') return 1;
      if (sortDir === 'ASC') return -1;
      return 0;
    }
  });
};
