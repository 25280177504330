import React, { useState } from 'react';
import styled from 'styled-components';
import { Popover, InputGroup, Icon } from '@blueprintjs/core';
import { NORMAL_GRAY_BLUE } from '../../styles/constants';
import SearchMenu from '../search-menu';
import { foundAtLeastOneMatch } from '../../utils/searchHelpers';

const IconStyled = styled(Icon)`
  margin-top: 10px;
  margin-right: 10px;
`;

const SearchButton = (
  <IconStyled icon={'search'} iconSize={20} color={NORMAL_GRAY_BLUE} />
);

const SearchBar = ({ recordings }) => {
  const [searchInput, setSearchInput] = useState('');

  const isSearchBarOpen =
    searchInput &&
    searchInput.length >= 2 &&
    recordings &&
    foundAtLeastOneMatch(searchInput, recordings);

  return (
    <Popover
      popoverClassName="bp3-popover-content-sizing"
      isOpen={isSearchBarOpen}
      content={<SearchMenu searchInput={searchInput} recordings={recordings} />}
      target={
        <InputGroup
          large={true}
          rightElement={SearchButton}
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search"
          small={false}
          value={searchInput}
        />
      }
    />
  );
};

export default SearchBar;
