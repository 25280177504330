// @flow

import React from 'react';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';
import LineIdSelector from './line-id-selector';
import CrossingLineDirectionTypeMenu from './crossing-line-direction-type-menu';

import * as ShapeModel from '../../models/shape';
import type { editingConfigActionT } from './editing-item-form';

const Table = styled.table`
  text-align: left;
  align-items: left;
  tr > td,
  th {
    padding: 0.5rem 1rem;
  }
`;

type Props = {
  currentRenderedShapes: Array<ShapeModel.t>,
  actions: Array<editingConfigActionT>,
  setShapeColor(shape: ShapeModel.t): void,
  handleDeleteAction(index: number): void,
  handleCrossingType(): void,
  handleSelectLineId(): void,
};

const LineEditingMenu = ({
  currentRenderedShapes,
  actions,
  setShapeColor,
  handleSelectLineId,
  handleCrossingType,
  handleDeleteAction,
}: Props): React$Element<any> => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Line Id</th>
          <th>Line Direction</th>
          <th>Remove Line</th>
        </tr>
      </thead>
      <tbody>
        {actions &&
          actions.map((action, actionIndex) => {
            return (
              <tr key={actionIndex}>
                <td flex={1}>
                  <LineIdSelector
                    currentRenderedShapes={currentRenderedShapes}
                    action={action}
                    setShapeColor={setShapeColor}
                    handleSelectLineId={handleSelectLineId}
                    index={actionIndex}
                  />
                </td>
                <td flex={1}>
                  <CrossingLineDirectionTypeMenu
                    action={action}
                    index={actionIndex}
                    handleCrossingType={handleCrossingType}
                  />
                </td>
                <td>
                  <Button
                    icon="remove"
                    intent="danger"
                    onClick={() => handleDeleteAction(actionIndex)}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default LineEditingMenu;
