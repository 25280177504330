function sqr(x) {
  return x * x;
}

function distancePointToPoint(segmentPointA, segmentPointB) {
  // calculate the distante btween two points
  return (
    sqr(segmentPointA.x - segmentPointB.x) +
    sqr(segmentPointA.y - segmentPointB.y)
  );
}

function distanceToSegment(point, segmentPointA, segmentPointB) {
  // Calculate the distance between a point and a segment (a line between two points)
  const segmentDistance = distancePointToPoint(segmentPointA, segmentPointB);
  if (segmentDistance === 0) return distancePointToPoint(point, segmentPointA);
  let t =
    ((point.x - segmentPointA.x) * (segmentPointB.x - segmentPointA.x) +
      (point.y - segmentPointA.y) * (segmentPointB.y - segmentPointA.y)) /
    segmentDistance;
  t = Math.max(0, Math.min(1, t));
  return distancePointToPoint(point, {
    x: segmentPointA.x + t * (segmentPointB.x - segmentPointA.x),
    y: segmentPointA.y + t * (segmentPointB.y - segmentPointA.y)
  });
}

export const getDistancesFromPoint = (point, coordssList) => {
  return coordssList.map((coordInList, index) => {
    const nextPointIndex = index === coordssList.length - 1 ? 0 : index + 1;
    const nextPoint = coordssList[nextPointIndex];

    const currentPointConfig = {
      x: coordInList[0],
      y: coordInList[1]
    };
    const nextPointConfig = {
      x: nextPoint[0],
      y: nextPoint[1]
    };

    const distance = distanceToSegment(
      point,
      currentPointConfig,
      nextPointConfig
    );
    return {
      index,
      distance
    };
  });
};

export const getPolyWithCloserSegmentsInOrder = (poly, newPoints) => {
  const copyPoly = [...poly];
  const distances = getDistancesFromPoint(newPoints, poly);
  const sortedDistancesByDesc = distances.sort(
    (a, b) => a.distance - b.distance
  );
  const closestPoint = sortedDistancesByDesc[0];
  copyPoly.splice(closestPoint.index + 1, 0, [newPoints.x, newPoints.y]);

  return copyPoly;
};

export const getPolyWithCloserPointsInOrder = (poly, newPoints) => {
  const copyPoly = [...poly];
  const distances = poly.map((coords, index) => {
    const pointToCompareConfig = {
      x: coords[0],
      y: coords[1]
    };
    const distance = distancePointToPoint(newPoints, pointToCompareConfig);
    return {
      ...pointToCompareConfig,
      index,
      distance
    };
  });
  const sortedDistancesByDesc = distances.sort(
    (a, b) => a.distance - b.distance
  );
  const closestPoint = sortedDistancesByDesc[0];
  copyPoly.splice(closestPoint.index, 1);

  return copyPoly;
};
