import React from 'react';
import { PopOverContainer } from './../../styles/templates';
import { H4, InputGroup, Button } from '@blueprintjs/core';

export default function PopOverChangeNameMenu({
  shape,
  changeShapeNameInput,
  handleChangeShapeNameInput,
  handleNameChange
}) {
  return (
    <PopOverContainer>
      <H4>Change Name</H4>
      <InputGroup
        type="text"
        leftIcon="edit"
        placeholder="New name"
        value={changeShapeNameInput}
        onChange={e => handleChangeShapeNameInput(e.target.value)}
      />
      <Button
        fill={true}
        intent="primary"
        onClick={() => handleNameChange(shape)}
      >
        Change Name
      </Button>
    </PopOverContainer>
  );
}
