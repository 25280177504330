// @flow
import React from 'react';
import { Popover, Menu, Button, MenuItem } from '@blueprintjs/core';
import {
  LOCATION_ENTRY_LINE,
  LOCATION_PASS_BY,
  WITHIN_LOCATION_MOVEMENT,
  WITHIN_LOCATION_ENTRY,
  WITHIN_LOCATION_PASS_BY,
} from '../../constants/line-context';

type Props = {
  currentUseType?: string | null,
  handleLineType(currentUseType: string): void,
};

const LineTypeMenu = ({ currentUseType, handleLineType }: Props): React$Element<any> => {
  return (
    <Popover>
      <Button text={currentUseType || 'Select'} rightIcon="caret-down" />
      <Menu>
        <MenuItem
          text="location-entry"
          onClick={(e) => handleLineType(LOCATION_ENTRY_LINE)}
        />
        <MenuItem
          text="location-pass-by"
          onClick={(e) => handleLineType(LOCATION_PASS_BY)}
        />
        <MenuItem
          text="within-location-movement"
          onClick={(e) => handleLineType(WITHIN_LOCATION_MOVEMENT)}
        />
        <MenuItem
          text="within-location-entry"
          onClick={(e) => handleLineType(WITHIN_LOCATION_ENTRY)}
        />
        <MenuItem
          text="within-location-pass-by"
          onClick={(e) => handleLineType(WITHIN_LOCATION_PASS_BY)}
        />
      </Menu>
    </Popover>
  );
};

export default LineTypeMenu;
