import React, { Fragment, Component } from 'react';
import MenuListElement from '../menu-list-element';
import ShapesContexts from '../shape-contexts';
import LineContexts from '../line-contexts';
import { EditShapesHeader } from '../edit-shapes-header';
import {
  AREA,
  LINE,
  AREA_CONTEXTS,
  LINE_CONTEXTS,
} from '../../constants/shapes';
import { ButtonGroup, Button } from '@blueprintjs/core';
export default class MenuEditShapes extends Component {
  state = {
    currentActiveMenuSection: AREA,
    currentMenuOpen: {
      id: '',
      type: '',
    },
  };

  handleCurrentActiveMenuSection(option) {
    this.setState({
      currentActiveMenuSection: option,
    });
  }

  handleCurrentMenuOpen(shape) {
    if (
      this.state.currentMenuOpen.id === shape.id &&
      this.state.currentMenuOpen.type === shape.type
    ) {
      this.setState({ currentMenuOpen: {} });
    } else {
      this.setState({
        currentMenuOpen: {
          id: shape.id,
          type: shape.type,
        },
      });
    }
  }

  render() {
    const {
      currentRenderedShapes,
      keysAndValuesInUse,
      showVarLines,
      handleShowVarLines,
      loading,
    } = this.props;
    const { currentActiveMenuSection } = this.state;
    return (
      <Fragment>
        <ButtonGroup>
          <Button
            onClick={() => this.handleCurrentActiveMenuSection(AREA)}
            icon="polygon-filter"
            intent={AREA === currentActiveMenuSection ? 'success' : 'none'}
          >
            Areas
          </Button>
          <Button
            onClick={() => this.handleCurrentActiveMenuSection(LINE)}
            icon="flow-linear"
            intent={LINE === currentActiveMenuSection ? 'success' : 'none'}
          >
            Lines
          </Button>
          <Button
            onClick={() => this.handleCurrentActiveMenuSection(AREA_CONTEXTS)}
            icon="send-to-graph"
            intent={
              AREA_CONTEXTS === currentActiveMenuSection ? 'success' : 'none'
            }
          >
            Area contexts
          </Button>
          <Button
            onClick={() => this.handleCurrentActiveMenuSection(LINE_CONTEXTS)}
            icon="new-link"
            intent={
              LINE_CONTEXTS === currentActiveMenuSection ? 'success' : 'none'
            }
          >
            Line contexts
          </Button>
        </ButtonGroup>
        {currentActiveMenuSection === AREA && (
          <EditShapesHeader
            type={AREA}
            title="Areas"
            createNewShape={this.props.createNewShape}
          />
        )}

        {currentActiveMenuSection === AREA &&
          currentRenderedShapes &&
          currentRenderedShapes.length > 0 &&
          currentRenderedShapes
            .filter((shape) => shape.type === AREA)
            .map((shape, index) => (
              <MenuListElement
                key={`${shape.id}-${index}`}
                shape={shape}
                setShapeColor={this.props.setShapeColor}
                handleCurrentMenuOpen={this.handleCurrentMenuOpen.bind(this)}
                currentMenuOpen={this.state.currentMenuOpen}
                deleteShape={this.props.deleteShape}
                addNewPoint={this.props.addNewPoint}
                deletePoint={this.props.deletePoint}
                changeAreaIgnoreType={this.props.changeAreaIgnoreType}
                changeShapeName={this.props.changeShapeName}
                copyShape={this.props.copyShape}
                toggleShapeVisibility={this.props.toggleShapeVisibility}
              />
            ))}

        {currentActiveMenuSection === LINE && (
          <EditShapesHeader
            type={LINE}
            title="Lines"
            createNewShape={this.props.createNewShape}
            handleShowVarLines={handleShowVarLines}
            showVarLines={showVarLines}
          />
        )}
        {currentActiveMenuSection === LINE &&
          currentRenderedShapes &&
          currentRenderedShapes.length > 0 &&
          currentRenderedShapes
            .filter((shape) => shape.type === LINE)
            .map((shape, index) => (
              <MenuListElement
                key={`${shape.id}-${index}`}
                shape={shape}
                setShapeColor={this.props.setShapeColor}
                handleCurrentMenuOpen={this.handleCurrentMenuOpen.bind(this)}
                currentMenuOpen={this.state.currentMenuOpen}
                deleteShape={this.props.deleteShape}
                changeShapeName={this.props.changeShapeName}
                changeLineArrowDirection={this.props.changeLineArrowDirection}
                copyShape={this.props.copyShape}
                saveNewLineVar={this.props.saveNewLineVar}
                toggleShapeVisibility={this.props.toggleShapeVisibility}
              />
            ))}
        {currentActiveMenuSection === AREA_CONTEXTS && (
          <ShapesContexts
            recording={this.props.recording}
            currentRenderedShapes={currentRenderedShapes}
            taxonomiesInUse={this.props.taxonomiesInUse}
            setShapeColor={this.props.setShapeColor}
            updateEditRecordingPageState={
              this.props.updateEditRecordingPageState
            }
            loadingTaxonomies={loading}
          />
        )}
        {currentActiveMenuSection === LINE_CONTEXTS && (
          <LineContexts
            recording={this.props.recording}
            currentRenderedShapes={currentRenderedShapes}
            taxonomiesInUse={this.props.taxonomiesInUse}
            setShapeColor={this.props.setShapeColor}
            keysAndValuesInUse={keysAndValuesInUse}
            updateEditRecordingPageState={
              this.props.updateEditRecordingPageState
            }
            loadingTaxonomies={loading}
          />
        )}
      </Fragment>
    );
  }
}
