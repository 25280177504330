import React, { useState, useEffect, useRef } from 'react';
import {
  Drawer,
  FormGroup,
  InputGroup,
  Checkbox,
  Button,
  Callout
} from '@blueprintjs/core';
import styled from 'styled-components';
import { errorToast } from '../../../utils/toaster';
import getRoleMappings from '../../../utils/userRoleMappings';
import * as AuthAPI from '../../../services/auth-api';

const FormWrapper = styled.form`
  width: 90%;
  margin: 2em auto;
`;
const SmallLabel = styled.p`
  color: #555;
  font-size: 12px;
  margin: 0 0 2em 0;
`;

export default ({ isOpen, onClose, selectedOrg }) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inviteResponse, setInviteResponse] = useState({});
  const formRef = useRef(null);
  const emailInput = useRef(null);
  const roleCheckboxes = useRef(null);

  const roleMappings = getRoleMappings(selectedOrg.name);

  useEffect(() => {
    async function fetchRoles() {
      try {
        const { data } = await AuthAPI.roles.list();
        setRoles(data);
      } catch (e) {
        console.error(e);
      }
    }

    if (isOpen) {
      if (emailInput && emailInput.current) emailInput.current.focus();
      setInviteResponse({});
      fetchRoles();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!roleCheckboxes.current) return;

    const checkboxes = roleCheckboxes.current.querySelectorAll(
      'input[type=checkbox]'
    );
    if (!checkboxes) return;

    checkboxes.forEach(cb => {
      const { name = '' } = cb;
      const { checkedByDefaultForNewUserInvite } = roleMappings[name];
      if (checkedByDefaultForNewUserInvite && cb) cb.checked = true;
    });
  }, [roleMappings, roles]);

  const inviteUser = async e => {
    e.preventDefault();

    const email = emailInput.current.value;
    const roleIds = Array.from(
      roleCheckboxes.current.querySelectorAll('input[type=checkbox]:checked')
    ).map(el => el.value);

    if (!roleIds || !roleIds.length) {
      errorToast({
        message: 'No roles selected'
      });
      return false;
    }

    setLoading(true);
    setInviteResponse({});

    try {
      await AuthAPI.invite.send(email, roleIds, selectedOrg);
      setInviteResponse({ type: 'success', text: 'Invite sent to user' });
    } catch (e) {
      setInviteResponse({
        type: 'warning',
        text: 'There was an error'
      });
    }

    setLoading(false);
  };

  return (
    <Drawer
      icon="person"
      title="Add a new user"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormWrapper ref={formRef} onSubmit={inviteUser}>
        <FormGroup
          helperText="This needs to be an actual email address - this person will recieve an email to set their password"
          label="Email address"
          labelFor="text-input"
          labelInfo="(required)"
        >
          <InputGroup
            id="text-input"
            required
            inputRef={emailInput}
            type="email"
          />
        </FormGroup>
        <FormGroup label="Organisation" labelFor="text-input">
          <InputGroup id="text-input" value={selectedOrg.name} disabled />
        </FormGroup>
        <FormGroup label="Roles">
          <div ref={roleCheckboxes}>
            {roles.map(role => {
              const roleMapping = roleMappings[role.name];
              if (!roleMapping)
                throw new Error(
                  'Role mapping not found; did a role name change?'
                );
              const {
                createDisabled,
                deleteDisabled,
                label,
                description
              } = roleMapping;

              return (
                <div key={role.id}>
                  <Checkbox
                    disabled={createDisabled || deleteDisabled}
                    name={role.name}
                    value={role.id}
                  >
                    {label}
                  </Checkbox>
                  <SmallLabel>{description}</SmallLabel>
                </div>
              );
            })}
          </div>
        </FormGroup>
        <Button type="submit" loading={loading} intent="primary">
          Add user to this organisation
        </Button>
        {inviteResponse.type && (
          <Callout intent={inviteResponse.type}>{inviteResponse.text}</Callout>
        )}
      </FormWrapper>
    </Drawer>
  );
};
