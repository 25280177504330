import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import {
  MenuItem,
  Button,
  Callout,
  Spinner,
  Alert,
  Tag
} from '@blueprintjs/core';
import ReactDataGrid from 'react-data-grid';
import { Select } from '@blueprintjs/select';
import { map } from 'awaity';

import { successToast } from '../../../utils/toaster';
import Navigation from '../../navigation';
import InviteUser from '../../users-drawers/invite-user';
import EditUser from '../../users-drawers/edit-user';
import RolesForUser from '../../users-drawers/roles-for-user';

import { organisations } from '../../../services/api';
import * as AuthAPI from '../../../services/auth-api';
import { DASHBOARD_APP_BASE } from '../../../config/vars';
import { genericSorter } from '../../../utils/dataGridTableSorting';

import UsersHeader from '../../users-header';
import UserActionsDropdown from './user-actions-dropdown';
import { userModelColumns } from './user-model-columns';
import { sortByName } from '../../../utils/sortingHelpers';

const Row = styled.div`
  width: 95%;
  max-width: 1600px;
  margin: 1em auto;
`;

const EmptyRowWrapper = styled.div`
  padding: 5em 3em;
  background: #f3f3f3;
  border-radius: 3px;
  color: #555;
`;

class UsersPage extends Component {
  state = {
    orgs: [],
    users: [],
    selectedOrgId: null,
    selectedOrgName: null,
    loadingUsers: false,
    isInviteUserDrawerOpen: false,
    userForDeletion: null,
    deleteUserAlertIcon: 'trash',
    userForEdit: null,
    userForRolesEdit: null
  };

  async componentDidMount() {
    try {
      const orgs = await organisations.listAll();
      this.setState({ orgs });
    } catch (e) {
      console.error(e);
    }
  }

  filterOrg(query, org) {
    return org.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }

  setSelectedOrgId(selectedOrgId) {
    const selectedOrgName = this.getOrgNameFromState(selectedOrgId);
    this.setState({ selectedOrgId, selectedOrgName });
    this.fetchUsers(selectedOrgId);
  }

  getOrgNameFromState(orgId) {
    const org = this.state.orgs.find(o => o.id === orgId);
    if (!org || !org.name) return 'Name not found';
    return org.name;
  }

  async fetchUsers(selectedOrgId) {
    try {
      this.setState({ loadingUsers: true });
      const { data: users } = await AuthAPI.users.list(selectedOrgId);
      const { data: adoptedUsers } = await AuthAPI.users.listAdopted(
        selectedOrgId
      );

      const allUsers = [
        ...users.map(u => ({
          ...u,
          adopted: false
        })),
        ...adoptedUsers.map(u => ({
          ...u,
          adopted: true
        }))
      ];

      const usersWithOrgNames = allUsers.map(user => ({
        ...user,
        primaryOrgName: this.getOrgNameFromState(user.primaryOrgId),
        ownerOrgName: this.getOrgNameFromState(user.ownerOrgId)
      }));

      this.setState({ users: usersWithOrgNames, loadingUsers: false });

      const usersWithTheirOrgs = await map(usersWithOrgNames, async u => {
        try {
          const { data: orgs } = await AuthAPI.users.organisationsForUser(u.id, true);
          return {
            ...u,
            usersOrgs: orgs.map(org => ({
              id: org.orgId,
              name: this.getOrgNameFromState(org.orgId)
            }))
          };
        } catch (e) {
          return {
            ...u,
            usersOrgs: 'Unknown'
          };
        }
      });

      this.setState({ users: usersWithTheirOrgs });
    } catch (e) {
      console.error(e);
      this.setState({
        users: [],
        loadingUsers: false
      });
    }
  }

  setIsInviteUserDrawerOpen(newValue) {
    const isInviteUserDrawerOpen =
      typeof newValue === 'boolean'
        ? newValue
        : !this.state.isInviteUserDrawerOpen;
    this.setState({ isInviteUserDrawerOpen });

    // the drawer just closed - refetch the users list
    if (newValue === false) {
      this.fetchUsers(this.state.selectedOrgId);
    }
  }

  onEditUserDrawerClose() {
    this.setState({ userForEdit: null });
    // the drawer just closed - refetch the users list
    this.fetchUsers(this.state.selectedOrgId);
  }

  onEditUserRolesDrawerClose() {
    this.setState({ userForRolesEdit: null });
    // the drawer just closed - refetch the users list
    this.fetchUsers(this.state.selectedOrgId);
  }

  render() {
    const {
      orgs = [],
      selectedOrgId,
      selectedOrgName,
      users = [],
      loadingUsers,
      isInviteUserDrawerOpen,
      userForDeletion,
      deleteUserAlertIcon,
      userForEdit,
      userForRolesEdit
    } = this.state;

    const selectedOrg = orgs.find(o => o.id === selectedOrgId) || {};

    const getImpersonateAction = auth0Id => ({
      icon: (
        <a
          href={`${DASHBOARD_APP_BASE}/?impersonateId=${encodeURIComponent(
            auth0Id
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button intent="primary" text="Impersonate" />
        </a>
      ),
      callback: () => {} // needs a callback or it throws an error
    });

    const getActions = user => ({
      icon: (
        <UserActionsDropdown
          onDelete={() => this.setState({ userForDeletion: user })}
          onEdit={() => this.setState({ userForEdit: user })}
          onRolesEdit={() => this.setState({ userForRolesEdit: user })}
        />
      ),
      callback: () => {}
    });

    const showEmptyRowWrapper = loadingUsers || !selectedOrgId || !users.length;

    return (
      <Fragment>
        <Navigation />
        <Row>
          <UsersHeader />
        </Row>
        <Row>
          <Callout intent="primary" icon="zoom-in" title="Filter">
            <Select
              items={orgs.sort(sortByName)}
              itemRenderer={(org, { handleClick }) => (
                <MenuItem
                  key={org.id}
                  text={org.name}
                  label={org.id}
                  active={org.id === selectedOrgId}
                  onClick={handleClick}
                />
              )}
              itemPredicate={this.filterOrg}
              onItemSelect={org => this.setSelectedOrgId(org.id)}
            >
              <Button
                text={selectedOrgId ? selectedOrg.name : 'Select organisation'}
                rightIcon="caret-down"
              />
            </Select>
            {selectedOrg.id && <Tag>Org ID: {selectedOrg.id}</Tag>}
          </Callout>
        </Row>
        <Row>
          {showEmptyRowWrapper && (
            <EmptyRowWrapper>
              {loadingUsers ? (
                <Spinner />
              ) : !selectedOrgId ? (
                <h3>Select an organisation</h3>
              ) : (
                <h3>No users found for this organisation</h3>
              )}
            </EmptyRowWrapper>
          )}

          {!showEmptyRowWrapper && (
            <ReactDataGrid
              columns={userModelColumns}
              rowGetter={i => users[i]}
              rowsCount={users.length}
              minHeight={600}
              getCellActions={(col, row) =>
                col.key === 'actions'
                  ? [getImpersonateAction(row.auth0Id), getActions(row)]
                  : null
              }
              onGridSort={(column, sortDir) => {
                this.setState({
                  users: genericSorter(users, column, sortDir)
                });
              }}
              enableCellSelect={true}
            />
          )}
        </Row>
        {selectedOrgId && (
          <Row>
            <Callout>
              <Button
                text="Invite new user to this organisation"
                icon="person"
                onClick={() => this.setIsInviteUserDrawerOpen(true)}
                intent="primary"
              />
            </Callout>
          </Row>
        )}
        <InviteUser
          isOpen={isInviteUserDrawerOpen}
          onClose={() => this.setIsInviteUserDrawerOpen(false)}
          selectedOrg={selectedOrg}
        />
        <EditUser
          isOpen={!!userForEdit}
          onClose={() => this.onEditUserDrawerClose()}
          selectedUser={userForEdit}
        />
        <RolesForUser
          isOpen={!!userForRolesEdit}
          onClose={() => this.onEditUserRolesDrawerClose()}
          selectedUser={userForRolesEdit}
          selectedOrg={{ id: selectedOrgId, name: selectedOrgName }}
        />
        <Alert
          isOpen={!!userForDeletion}
          cancelButtonText="Cancel"
          canEscapeKeyCancel
          canOutsideClickCancel
          onCancel={() =>
            this.setState({
              userForDeletion: null,
              deleteUserAlertIcon: 'trash'
            })
          }
          icon={deleteUserAlertIcon}
          confirmButtonText="Delete user"
          intent="danger"
          onConfirm={async () => {
            this.setState({ deleteUserAlertIcon: <Spinner size={40} /> });
            try {
              await AuthAPI.users.delete(userForDeletion.id);
            } catch (e) {
              console.error(e);
              alert('Could not delete user');
            }
            successToast({
              message: `${userForDeletion && userForDeletion.email} deleted`
            });
            this.setState({
              deleteUserAlertIcon: 'trash',
              userForDeletion: null
            });
            this.fetchUsers(selectedOrgId);
          }}
        >
          Are you sure you want to delete{' '}
          {userForDeletion && userForDeletion.email}?
        </Alert>
      </Fragment>
    );
  }
}

export default UsersPage;
