import React, { useState, useEffect } from 'react';
import { pick } from 'lodash';
import {
  Drawer,
  FormGroup,
  InputGroup,
  Button,
  Callout,
  Radio,
  RadioGroup,
} from '@blueprintjs/core';
import styled from 'styled-components';
import * as AuthAPI from '../../../services/auth-api';

const FormWrapper = styled.form`
  width: 90%;
  margin: 2em auto;
`;

export default ({ isOpen, onClose, selectedUser }) => {
  const [loading, setLoading] = useState(false);
  const [APIResponse, setAPIResponse] = useState({});
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (isOpen && selectedUser) {
      setAPIResponse({});
      setEmail(selectedUser.email);
      setFullName(selectedUser.fullName);
      setStatus(selectedUser.status);
    }
  }, [isOpen, selectedUser]);

  const editUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAPIResponse({});

    try {
      await AuthAPI.users.update(selectedUser.id, {
        ...pick(selectedUser, 'primaryOrgId'),
        email,
        fullName,
        status,
      });
      setAPIResponse({ type: 'success', text: 'User updated' });
    } catch (e) {
      setAPIResponse({
        type: 'warning',
        text: 'There was an error. User not updated',
      });
    }

    setLoading(false);
  };

  return (
    <Drawer
      icon="person"
      title={`Edit ${selectedUser && selectedUser.email}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormWrapper onSubmit={editUser}>
        <FormGroup label="Email" labelFor="email">
          <InputGroup id="email" value={email} disabled />
        </FormGroup>
        <FormGroup label="Full name" labelFor="fullName">
          <InputGroup
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </FormGroup>
        <RadioGroup
          label="Status"
          onChange={(e) => setStatus(e.target.value)}
          selectedValue={status}
        >
          <Radio label="Active" value="active" />
          <Radio
            label="Created (newly created user - will be prompted for their name on the dashboard)"
            value="created"
          />
          <Radio
            label="Inactive (user will still exist, and they will still be able to log in, but won't be able to access any data)"
            value="inactive"
          />
        </RadioGroup>

        <Button type="submit" loading={loading} intent="primary">
          Update user
        </Button>
        {APIResponse.type && (
          <Callout intent={APIResponse.type}>{APIResponse.text}</Callout>
        )}
      </FormWrapper>
    </Drawer>
  );
};
