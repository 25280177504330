// @flow

import React from 'react';
import {
  Popover,
  Tooltip,
  Menu,
  MenuItem,
  Position,
  Button
} from '@blueprintjs/core';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  margin-left: 1rem;
`;

type Props = {
  list: string[],
  index: number,
  type: 'value' | 'key',
  handleEditMetadataItem: (e: Object, index: number, editing: string) => void
};

const KeysAndValuesMenu = ({
  list,
  index,
  type,
  handleEditMetadataItem
}: Props): React$Element<any> => (
  <Popover>
    <Tooltip
      position={Position.TOP}
      content={<p>Choose from existing {type}s</p>}
    >
      <StyledButton intent="primary" icon="add-to-artifact" />
    </Tooltip>
    <Menu>
      {list.map((allMetadataValue, i) => (
        <MenuItem
          key={i}
          text={allMetadataValue}
          onClick={() => handleEditMetadataItem(allMetadataValue, index, type)}
        />
      ))}
    </Menu>
  </Popover>
);

export default KeysAndValuesMenu;
