import React, { useState, useEffect } from 'react';
import {
  Drawer,
  FormGroup,
  HTMLSelect,
  Button,
  Callout,
  Spinner
} from '@blueprintjs/core';
import styled from 'styled-components';
import getRoleMappings from '../../../utils/userRoleMappings';
import * as AuthAPI from '../../../services/auth-api';

const FormWrapper = styled.div`
  width: 90%;
  margin: 2em auto;
`;
const SmallLabel = styled.p`
  color: #555;
  font-size: 12px;
  margin: 0;
  ${props => (props.marginBottom ? 'margin-bottom: 1em' : '')}
`;
const Table = styled.table`
  width: 100%;
  margin: 2em 0;
`;
const CalloutWrapper = styled.form`
  margin: 2em 0;
`;

export default ({ isOpen, onClose, selectedUser, selectedOrg }) => {
  const [currentRoles, setCurrentRoles] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [roleToAddToUser, setRoleToAddToUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [roleUpdateResponse, setRoleUpdateResponse] = useState({});

  const roleMappings = getRoleMappings(selectedOrg.name);

  useEffect(() => {
    async function fetchRoles() {
      try {
        setLoading(true);
        const { data: allRoles } = await AuthAPI.roles.list();
        const { data: currentRolesToSet } = await AuthAPI.users.rolesForUser(
          selectedUser.id,
          selectedOrg.id
        );
        const availableRolesToSet = allRoles.filter(r => {
          const roleMapping = roleMappings[r.name] || {};
          if (roleMapping.createDisabled) return false;

          return !currentRolesToSet.find(cr => cr.id === r.id);
        });
        setAvailableRoles(availableRolesToSet);
        setCurrentRoles(currentRolesToSet);
        if (availableRolesToSet.length > 0) {
          setRoleToAddToUser(availableRolesToSet[0].id);
        } else {
          setRoleToAddToUser('');
        }

        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }

    if (isOpen) {
      fetchRoles();
      setRoleUpdateResponse({});
    }
  }, [isOpen, selectedOrg, selectedUser]); // eslint-disable-line

  const removeRoleFromUser = async roleToRemove => {
    setRoleUpdateResponse({ type: 'info', text: 'Removing...' });
    try {
      await AuthAPI.users.removeRole(
        selectedUser.id,
        selectedOrg.id,
        roleToRemove.id
      );

      setCurrentRoles(currentRoles.filter(r => r.id !== roleToRemove.id));
      const newAvailableRoles = [...availableRoles, ...[roleToRemove]];
      setAvailableRoles(newAvailableRoles);

      const newAvailableRoleId =
        newAvailableRoles.length > 0 ? newAvailableRoles[0].id : '';
      setRoleToAddToUser(newAvailableRoleId);

      setRoleUpdateResponse({
        type: 'success',
        text: 'Role removed from user'
      });
    } catch (e) {
      console.error(e);
      setRoleUpdateResponse({
        type: 'danger',
        text: 'Could not remove role from this user'
      });
    }
  };

  const addRoleToUser = async () => {
    if (!roleToAddToUser) return false;
    setRoleUpdateResponse({ type: 'info', text: 'Adding...' });
    try {
      await AuthAPI.users.addRole(
        selectedUser.id,
        selectedOrg.id,
        roleToAddToUser
      );

      const newlyAddedRole = availableRoles.find(
        r => Number(r.id) === Number(roleToAddToUser)
      );

      const newAvailableRoles = availableRoles.filter(
        r => Number(r.id) !== Number(roleToAddToUser)
      );
      setAvailableRoles(newAvailableRoles);
      setCurrentRoles([...currentRoles, ...[newlyAddedRole]]);

      const newAvailableRoleId =
        newAvailableRoles.length > 0 ? newAvailableRoles[0].id : '';
      setRoleToAddToUser(newAvailableRoleId);

      setRoleUpdateResponse({ type: 'success', text: 'Role added to user' });
    } catch (e) {
      console.error(e);
      setRoleUpdateResponse({
        type: 'danger',
        text: 'Could not add role to user'
      });
    }
  };

  return (
    <Drawer
      icon="person"
      title="View/Edit a user's roles"
      isOpen={isOpen}
      onClose={onClose}
    >
      {loading && <Spinner />}
      <FormWrapper>
        <h3>User's roles within the {selectedOrg.name} organisation</h3>
        <Table className="bp3-html-table bp3-html-table-bordered bp3-interactive">
          <thead>
            <tr>
              <th>Current roles applied to user</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRoles.map(role => {
              if (!role) return null;
              const roleMapping = roleMappings[role.name];
              if (!roleMapping)
                console.error(
                  'Role mapping not found; did a role name change?'
                );
              const { deleteDisabled, label, description } = roleMapping || {};

              return (
                <tr key={role.id}>
                  <td>
                    {label || role.name}
                    <SmallLabel>{description}</SmallLabel>
                  </td>
                  <td>
                    <Button
                      onClick={e => removeRoleFromUser(role)}
                      disabled={
                        deleteDisabled === false ? deleteDisabled : true
                      }
                      intent="warning"
                    >
                      Remove role from user
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <CalloutWrapper>
          <Callout title="Assign a new role to this user">
            <SmallLabel marginBottom>
              This only shows roles that are not marked as "Internal use only".
            </SmallLabel>
            <FormGroup>
              <HTMLSelect
                onChange={e => setRoleToAddToUser(e.target.value)}
                value={roleToAddToUser}
                options={
                  availableRoles.length === 0
                    ? [{ label: 'No other roles to add...', value: null }]
                    : availableRoles.map(role => {
                        const roleMapping = roleMappings[role.name] || {};
                        const {
                          label = role.name,
                          description = ''
                        } = roleMapping;
                        const desc = !!description ? `(${description})` : '';

                        return {
                          label: `${label} ${desc}`,
                          value: role.id,
                          disabled: roleMapping.createDisabled
                        };
                      })
                }
              />
              <Button text="Add" intent="primary" onClick={addRoleToUser} />
            </FormGroup>
          </Callout>
          {roleUpdateResponse.type && (
            <Callout intent={roleUpdateResponse.type}>
              {roleUpdateResponse.text}
            </Callout>
          )}
        </CalloutWrapper>
      </FormWrapper>
    </Drawer>
  );
};
