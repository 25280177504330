import React from 'react';
import { Checkbox } from '@blueprintjs/core';

const commonConf = {
  resizable: true,
  editable: true,
  sortable: true
};

export const roleModelColumns = [
  { key: 'id', name: 'Id', ...commonConf, width: 75 },
  { key: 'name', name: 'Name', ...commonConf },
  {
    key: 'auraAdminOnly',
    name: 'Internal use only',
    ...commonConf,
    width: 150,
    formatter: (row) => <Checkbox checked={!!row.value} />
  },
  { key: 'createdAt', name: 'Created', ...commonConf, width: 220 },
  { key: 'updatedAt', name: 'Last updated', ...commonConf, width: 220 },
  { key: 'actions', name: 'Actions', resizable: true, editable: true }
];

export const roleModelColumnsNoActions = roleModelColumns.filter(
  (col) => col.key !== 'actions'
);
