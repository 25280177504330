import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Navbar, Button, Alignment } from '@blueprintjs/core';
import FilterRecordingButtons from '../filter-recording-buttons';
import SearchBar from '../search-bar';

const HeaderMain = styled(Navbar)`
  margin-bottom: 1em;
`;

export default ({
  allOrgs,
  allLocations,
  selectedOrg,
  selectedLoc,
  setLocFilter,
  setOrgFilter,
  recordings,
  showBackButton = true
}) => (
  <HeaderMain>
    <Navbar.Group align={Alignment.LEFT}>
      <Navbar.Heading>
        {showBackButton && (
          <Link to="/cameras">
            <Button icon="home" text="Back to all recordings" />
          </Link>
        )}
      </Navbar.Heading>
    </Navbar.Group>
    <Navbar.Group align={Alignment.RIGHT}>
      {allOrgs && allLocations && (
        <FilterRecordingButtons
          allOrgs={allOrgs}
          allLocations={allLocations}
          selectedOrg={selectedOrg}
          selectedLoc={selectedLoc}
          setLocFilter={setLocFilter}
          setOrgFilter={setOrgFilter}
        />
      )}
      <SearchBar recordings={recordings} />
    </Navbar.Group>
  </HeaderMain>
);
