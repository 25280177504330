// @flow

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button, Classes } from '@blueprintjs/core';
import KeysAndValuesMenu from './keys-and-values-menu';

import * as LineContextModel from '../../models/line-context';

const METADATA_ITEM = {
  KEY: 'key',
  VALUE: 'value'
};

const Input = styled.input`
  margin-left: 1rem;
`;
const RowList = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;
const StyledButton = styled(Button)`
  margin-left: 1rem;
`;

type Props = {
  metadata?: Array<LineContextModel.metadataItem>,
  handleDeleteMetadaItem: (index: number) => void,
  handleEditMetadataItem: (e: Object, index: number, editing: string) => void,
  keysAndValuesInUse?: {
    keys: string[],
    values: string[]
  }
};

const MetadataEditor = ({
  metadata,
  handleDeleteMetadaItem,
  handleEditMetadataItem,
  keysAndValuesInUse
}: Props): React$Element<React$FragmentType> => {
  const { KEY, VALUE } = METADATA_ITEM;

  const commomInputProps = {
    type: 'text',
    width: '80%',
    style: { width: '150px' },
    className: Classes.INPUT
  };

  return (
    <Fragment>
      {metadata &&
        metadata.map(({ key, value }, index) => (
          <RowList key={index}>
            {keysAndValuesInUse &&
              keysAndValuesInUse.keys &&
              keysAndValuesInUse.keys.length > 0 && (
                <KeysAndValuesMenu
                  index={index}
                  list={keysAndValuesInUse.keys}
                  type={KEY}
                  handleEditMetadataItem={handleEditMetadataItem}
                />
              )}
            <Input
              {...commomInputProps}
              value={key}
              onChange={e =>
                handleEditMetadataItem(e.currentTarget.value, index, KEY)
              }
              placeholder="Enter a key"
            />
            {keysAndValuesInUse &&
              keysAndValuesInUse.values &&
              keysAndValuesInUse.values.length > 0 && (
                <KeysAndValuesMenu
                  index={index}
                  list={keysAndValuesInUse.values}
                  type={VALUE}
                  handleEditMetadataItem={handleEditMetadataItem}
                />
              )}
            <Input
              {...commomInputProps}
              value={value}
              onChange={e =>
                handleEditMetadataItem(e.currentTarget.value, index, VALUE)
              }
              placeholder="Enter a value"
            />
            <StyledButton
              icon="remove"
              intent="danger"
              onClick={() => handleDeleteMetadaItem(index)}
            />
          </RowList>
        ))}
    </Fragment>
  );
};

export default MetadataEditor;
