import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Classes, Card, Button, Tooltip, Position } from '@blueprintjs/core';
import {
  areasRendering,
  linesRendering,
  getPointBeingClicked,
  getMousePosition,
  getConfPointObj,
  getShapeBeingClicked,
  doesShapeNameExist,
} from '../../utils/canvasHelpers';
import {
  getPolyWithCloserSegmentsInOrder,
  getPolyWithCloserPointsInOrder,
} from '../../utils/shapesHelpers';
import EditButton from '../edit-button';
import MenuEditShapes from '../menu-edit-shapes';
import { errorToast, successToast } from '../../utils/toaster';
import { YELLOW, GREEN, RED, BLUE } from '../../styles/constants';
import { AREA, LINE } from '../../constants/shapes';
import { CreateStandardElems } from '../create-standard-elems';
import { statElems } from '../../services/api';
import { apiObjMaker } from '../../utils/editButtonHelpers';

const HEADER_HEIGHT = '50px';
const EM_TWICE = '28px';

const canvasStyle = {
  display: 'block',
  position: 'relative',
  width: 'fit-content',
  height: 'auto',
};

const WrapperMenuEditShapes = styled(Card)`
  overflow-y: auto;
  height: 100%;
`;

const CanvasContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  min-width: 100%;
  padding-bottom: 1em;
  display: flex;
  gap: 1rem;
`;

const CanvasColumn = styled.div`
  display: block;
  &&& {
    width: fit-content;
  }
`;

const MenuColumn = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: auto;
  min-width: 300px;
  max-height: calc(100vh - ${HEADER_HEIGHT} - ${EM_TWICE});
`;

const UndoButtonContainer = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
`;

class EditShapes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRenderedShapes: [],
      renderedShapesHistory: [],
      changesDoneButNotSaved: false,
      pointBeingDragged: {
        id: '',
        type: '',
        pointIndex: '',
      },
      shapeBeingDragged: {
        id: '',
        type: '',
        startingPoint: {
          x: 0,
          y: 0,
        },
      },
      colorsHash: {},
      isAddingNewPointBlock: false,
      isDeletingPointBlock: false,
      showVarLines: true,
    };
    this.canvas = React.createRef();
    this.hitCanvas = React.createRef();
  }

  stepBack() {
    if (this.state.renderedShapesHistory.length > 0) {
      const history = [...this.state.renderedShapesHistory];
      const lastState = history.pop();
      this.setState(
        {
          currentRenderedShapes: lastState,
          renderedShapesHistory: history,
        },
        () => {
          this.setChangesDoneButNotSaved(history.length > 0);
          this.updateCanvas();
        }
      );
    }
  }

  clearUndoHistory() {
    this.setState({
      renderedShapesHistory: [],
    });
  }

  changeLineArrowDirection(shape) {
    const { currentRenderedShapes } = this.state;
    const filteredShapes = currentRenderedShapes.map((currentShape) => {
      const newShape = JSON.parse(JSON.stringify(currentShape));

      if (newShape.id === shape.id) {
        newShape.points.inverted = !newShape.points.inverted;
      }

      return newShape;
    });

    this.setState(
      {
        currentRenderedShapes: filteredShapes,
        renderedShapesHistory: [
          ...this.state.renderedShapesHistory,
          this.state.currentRenderedShapes,
        ],
      },
      () => {
        this.setChangesDoneButNotSaved(true);
        this.updateCanvas();
      }
    );
  }

  handleShowVarLines() {
    this.setState(
      {
        showVarLines: !this.state.showVarLines,
      },
      this.updateCanvas
    );
  }

  isShapeBeingDragged() {
    return !!this.state.shapeBeingDragged.id;
  }

  isPointBeingDragged() {
    return !!this.state.pointBeingDragged.id;
  }

  setChangesDoneButNotSaved(value) {
    this.setState({
      changesDoneButNotSaved: value,
    });
  }

  async createNewShape(configShapeObj, ignoreDoneButNotSaved = false) {
    const { name, type, varValue } = configShapeObj;
    if (doesShapeNameExist(name, type, this.state.currentRenderedShapes)) {
      errorToast({
        message: 'That name is already in use, write another name',
      });
      return null;
    }
    const { currentRenderedShapes } = this.state;
    const copyCurrentRenderedShapes = JSON.parse(
      JSON.stringify(currentRenderedShapes)
    );
    let points = {};
    if (type === LINE) {
      points = {
        x0: 50,
        y0: 50,
        x1: 100,
        y1: 100,
        var: typeof varValue === 'number' ? varValue : 20,
      };
    } else if (type === AREA) {
      points = {
        poly: [
          [50, 50],
          [50, 100],
          [100, 100],
          [100, 50],
        ],
      };
    }

    const newShape = getConfPointObj(
      this.state.currentRenderedShapes,
      type,
      name,
      points
    );
    copyCurrentRenderedShapes.push(newShape);
    return new Promise((resolve) =>
      this.setState(
        {
          currentRenderedShapes: copyCurrentRenderedShapes,
          renderedShapesHistory: [
            ...this.state.renderedShapesHistory,
            this.state.currentRenderedShapes,
          ],
        },
        () => {
          if (!ignoreDoneButNotSaved) {
            this.setChangesDoneButNotSaved(true);
          }
          this.updateCanvas();
          this.updateColorsHash();
          resolve();
        }
      )
    );
  }

  toggleShapeVisibility(shapeId) {
    const newRenderedShapes = [...this.state.currentRenderedShapes];

    const selectedShape = newRenderedShapes.find((item) => item.id === shapeId);
    selectedShape.visible = !selectedShape.visible;

    this.setState(
      {
        currentRenderedShapes: newRenderedShapes,
      },
      () => {
        this.updateCanvas();
      }
    );
  }

  setShapeColor(activeShape) {
    const { currentRenderedShapes } = this.state;
    const renderedShapes = currentRenderedShapes.map((currentShape) => {
      const newShape = JSON.parse(JSON.stringify(currentShape));
      if (currentShape.type === AREA && !currentShape.isIgnoreArea)
        newShape.color = BLUE;
      if (currentShape.type === AREA && currentShape.isIgnoreArea)
        newShape.color = YELLOW;
      if (currentShape.type === LINE) newShape.color = RED;

      if (Array.isArray(activeShape)) {
        activeShape.forEach((currentActiveShape) => {
          if (
            currentShape.id === currentActiveShape.id &&
            currentShape.type === currentActiveShape.type
          ) {
            newShape.color = GREEN;
          }
        });
      } else {
        if (
          currentShape.id === activeShape.id &&
          currentShape.type === activeShape.type
        ) {
          newShape.color = GREEN;
        }
      }

      return newShape;
    });

    this.setState(
      {
        currentRenderedShapes: renderedShapes,
      },
      this.updateCanvas
    );
  }

  copyShape(shape) {
    let name = `${shape.id} - Copy`;
    while (
      doesShapeNameExist(name, shape.type, this.state.currentRenderedShapes)
    ) {
      name = `${name} - Copy`;
    }
    const { currentRenderedShapes } = this.state;
    const copyCurrentRenderedShapes = JSON.parse(
      JSON.stringify(currentRenderedShapes)
    );
    const newShape = getConfPointObj(
      this.state.currentRenderedShapes,
      shape.type,
      name,
      shape.points
    );
    copyCurrentRenderedShapes.push(newShape);

    this.setState(
      {
        currentRenderedShapes: copyCurrentRenderedShapes,
        renderedShapesHistory: [
          ...this.state.renderedShapesHistory,
          this.state.currentRenderedShapes,
        ],
      },
      () => {
        this.setChangesDoneButNotSaved(true);
        this.updateCanvas();
        this.updateColorsHash();
      }
    );
  }

  deleteShape(shape) {
    const { currentRenderedShapes } = this.state;
    const filteredShapes = currentRenderedShapes.filter((currentShape) => {
      const newShape = JSON.parse(JSON.stringify(currentShape));
      return newShape.id !== shape.id || newShape.type !== shape.type;
    });

    this.setState(
      {
        currentRenderedShapes: filteredShapes,
        renderedShapesHistory: [
          ...this.state.renderedShapesHistory,
          this.state.currentRenderedShapes,
        ],
      },
      () => {
        this.setChangesDoneButNotSaved(true);
        this.updateCanvas();
      }
    );
  }

  changeShapeName(shape, name) {
    const { currentRenderedShapes } = this.state;
    if (
      doesShapeNameExist(name, shape.type, this.state.currentRenderedShapes)
    ) {
      errorToast({
        message: 'That name is already in use, write another name',
      });
      return null;
    }
    const renderedShapes = currentRenderedShapes.map((shapeRendered) => {
      if (shapeRendered.id === shape.id) {
        shapeRendered.id = name;
      }
      return shapeRendered;
    });

    this.setState(
      {
        currentRenderedShapes: renderedShapes,
        renderedShapesHistory: [
          ...this.state.renderedShapesHistory,
          this.state.currentRenderedShapes,
        ],
      },
      () => {
        this.setChangesDoneButNotSaved(true);
        this.updateCanvas();
        this.updateColorsHash();
      }
    );
  }

  saveNewLineVar(shape, lineVar) {
    const { currentRenderedShapes } = this.state;
    const modifiedShapes = currentRenderedShapes.map((currentShape) => {
      let newShape = JSON.parse(JSON.stringify(currentShape));
      if (newShape.id === shape.id && newShape.type === shape.type) {
        newShape = {
          ...newShape,
          points: {
            ...newShape.points,
            var: lineVar,
          },
        };
      }

      return newShape;
    });

    this.setState(
      {
        currentRenderedShapes: modifiedShapes,
        renderedShapesHistory: [
          ...this.state.renderedShapesHistory,
          this.state.currentRenderedShapes,
        ],
      },
      () => {
        this.setChangesDoneButNotSaved(true);
        this.updateCanvas();
      }
    );
  }

  changeAreaIgnoreType(shape) {
    const { currentRenderedShapes } = this.state;
    const renderedShapes = currentRenderedShapes.map((shape) => {
      return JSON.parse(JSON.stringify(shape));
    });
    const index = renderedShapes.findIndex(
      (currentShape) =>
        currentShape.id === shape.id && currentShape.type === AREA
    );
    if (index >= 0) {
      renderedShapes[index].isIgnoreArea = !renderedShapes[index].isIgnoreArea;
      renderedShapes[index].color = YELLOW;
    }

    this.setState(
      {
        currentRenderedShapes: renderedShapes,
        renderedShapesHistory: [
          ...this.state.renderedShapesHistory,
          this.state.currentRenderedShapes,
        ],
      },
      () => {
        this.setChangesDoneButNotSaved(true);
        this.updateCanvas();
      }
    );
  }

  handleIsDeletingPointBlock(callback) {
    const { isDeletingPointBlock } = this.state;
    this.setState(
      {
        isDeletingPointBlock: !isDeletingPointBlock,
      },
      callback
    );
  }

  handleDeletePoint(event, canvas, eventPasser, shape, renderedShapes, index) {
    const mousePos = getMousePosition(event, canvas);
    if (mousePos) {
      const newPoly = getPolyWithCloserPointsInOrder(
        shape.points.poly,
        mousePos
      );
      renderedShapes[index].points.poly = newPoly;

      this.setState(
        {
          currentRenderedShapes: renderedShapes,
          renderedShapesHistory: [
            ...this.state.renderedShapesHistory,
            this.state.currentRenderedShapes,
          ],
        },
        () => {
          this.setChangesDoneButNotSaved(true);
          this.updateCanvas();
          this.handleIsDeletingPointBlock(
            this.canvas.current.removeEventListener('click', eventPasser)
          );
        }
      );
    }
  }

  handleIsAddingNewPointBlock(callback) {
    const { isAddingNewPointBlock } = this.state;
    this.setState(
      {
        isAddingNewPointBlock: !isAddingNewPointBlock,
      },
      callback
    );
  }

  handleAddNewPoint(event, canvas, eventPasser, shape, renderedShapes, index) {
    const mousePos = getMousePosition(event, canvas);
    if (mousePos) {
      const newPoly = getPolyWithCloserSegmentsInOrder(
        shape.points.poly,
        mousePos
      );
      renderedShapes[index].points.poly = newPoly;
      this.setState(
        {
          currentRenderedShapes: renderedShapes,
          renderedShapesHistory: [
            ...this.state.renderedShapesHistory,
            this.state.currentRenderedShapes,
          ],
        },
        () => {
          this.setChangesDoneButNotSaved(true);
          this.updateCanvas();
          this.handleIsAddingNewPointBlock(
            this.canvas.current.removeEventListener('click', eventPasser)
          );
        }
      );
    }
  }

  addNewPoint(shape) {
    const { currentRenderedShapes, isAddingNewPointBlock } = this.state;
    const renderedShapes = currentRenderedShapes.map((shape) => {
      return JSON.parse(JSON.stringify(shape));
    });
    const index = renderedShapes.findIndex(
      (currentShape) =>
        currentShape.id === shape.id && currentShape.type === AREA
    );

    if (index >= 0 && !isAddingNewPointBlock) {
      const eventPasser = (event) => {
        return this.handleAddNewPoint(
          event,
          this.canvas.current,
          eventPasser,
          shape,
          renderedShapes,
          index
        );
      };

      this.handleIsAddingNewPointBlock(
        this.canvas.current.addEventListener('click', eventPasser)
      );
    }
  }

  deletePoint(shape) {
    const { currentRenderedShapes, isDeletingPointBlock } = this.state;
    const renderedShapes = currentRenderedShapes.map((shape) => {
      return JSON.parse(JSON.stringify(shape));
    });
    const index = renderedShapes.findIndex(
      (currentShape) =>
        currentShape.id === shape.id && currentShape.type === 'area'
    );

    if (
      index >= 0 &&
      !isDeletingPointBlock &&
      renderedShapes[index].points.poly.length > 3
    ) {
      const eventPasser = (event) => {
        return this.handleDeletePoint(
          event,
          this.canvas.current,
          eventPasser,
          shape,
          renderedShapes,
          index
        );
      };

      this.handleIsDeletingPointBlock(
        this.canvas.current.addEventListener('click', eventPasser)
      );
    }
  }

  setPointBeingDragged(shape, index = 0) {
    this.setShapeColor(shape);
    this.setState(
      {
        pointBeingDragged: {
          id: shape.id,
          type: shape.type,
          pointIndex: index,
        },
        renderedShapesHistory: [
          ...this.state.renderedShapesHistory,
          this.state.currentRenderedShapes,
        ],
      },
      () => {
        this.setChangesDoneButNotSaved(true);
        this.updateCanvas();
      }
    );
  }

  clearPointBeingDragged() {
    this.setShapeColor({});
    this.setState(
      {
        pointBeingDragged: {},
      },
      this.updateCanvas
    );
  }

  async handleSaveChanges() {
    if (this.state.currentRenderedShapes && this.props.recording) {
      const objFormatAPI = apiObjMaker(
        this.state.currentRenderedShapes,
        this.props.recording
      );

      const response = await statElems.create(
        this.props.recording.id,
        objFormatAPI
      );

      if (response) {
        successToast({
          message: 'All changes were successfully done!',
        });
        this.setChangesDoneButNotSaved(false);
        this.clearUndoHistory();
      } else {
        const message = 'Your changes were not saved';
        errorToast({
          message,
        });
      }
    }
  }

  updateColorsHash() {
    const newColorsHash = {};
    this.state.currentRenderedShapes.forEach((shape) => {
      newColorsHash[shape.colorKey] = shape;
    });
    this.setState({
      colorsHash: newColorsHash,
    });
  }

  firstUpdateOfCurrentShapesInState() {
    const currentRenderedShapes = [];
    const { statElems } = this.props.recording;
    if (statElems) {
      if (Object.values(statElems.lines).length > 0) {
        Object.entries(statElems.lines).forEach(([key, value]) => {
          const linePointConfig = getConfPointObj(
            this.state.currentRenderedShapes,
            LINE,
            key,
            value
          );
          currentRenderedShapes.push(linePointConfig);
        });
      }

      if (
        Object.values(statElems.areas.trigger).length > 0 ||
        Object.values(statElems.areas.ignore).length > 0
      ) {
        Object.entries(statElems.areas.trigger).forEach(([key, value]) => {
          const areasPointConfig = getConfPointObj(
            this.state.currentRenderedShapes,
            AREA,
            key,
            value,
            BLUE
          );
          currentRenderedShapes.push(areasPointConfig);
        });
        Object.entries(statElems.areas.ignore).forEach(([key, value]) => {
          const areasPointConfig = getConfPointObj(
            this.state.currentRenderedShapes,
            AREA,
            key,
            value,
            YELLOW,
            undefined,
            true
          );
          currentRenderedShapes.push(areasPointConfig);
        });
      }
    }
    this.setState(
      {
        currentRenderedShapes,
      },
      () => {
        this.updateCanvas();
        this.updateColorsHash();
      }
    );
  }

  updateShapePosition(difference, index, mousePos) {
    const { currentRenderedShapes } = this.state;
    const renderedShapes = currentRenderedShapes.map((shape) => {
      return JSON.parse(JSON.stringify(shape));
    });

    let shape = renderedShapes[index];
    if (shape) {
      const { type } = shape;

      if (type === AREA) {
        shape.points.poly.forEach((coords, pointIndex) => {
          shape.points.poly[pointIndex] = [
            shape.points.poly[pointIndex][0] + difference.x,
            shape.points.poly[pointIndex][1] + difference.y,
          ];
        });
      } else if (type === LINE) {
        for (const pointIndex in [0, 1]) {
          shape.points[`x${pointIndex}`] =
            shape.points[`x${pointIndex}`] + difference.x;
          shape.points[`y${pointIndex}`] =
            shape.points[`y${pointIndex}`] + difference.y;
        }
      }

      this.setState(
        {
          currentRenderedShapes: renderedShapes,
        },
        () => {
          this.updateCanvas();
          this.updateCords(mousePos);
          this.setChangesDoneButNotSaved(true);
        }
      );
    }
  }

  updatePointPosition(mousePos) {
    const { type, pointIndex, id } = this.state.pointBeingDragged;
    const { currentRenderedShapes } = this.state;
    if (currentRenderedShapes) {
      const renderedShapes = currentRenderedShapes.map((shape) => {
        const newShape = JSON.parse(JSON.stringify(shape));
        if (newShape.type === type && newShape.id === id) {
          if (type === AREA) {
            newShape.points.poly[pointIndex] = [mousePos.x, mousePos.y];
          } else {
            newShape.points[`x${pointIndex}`] = mousePos.x;
            newShape.points[`y${pointIndex}`] = mousePos.y;
          }
          return newShape;
        }
        return newShape;
      });

      this.setState(
        {
          currentRenderedShapes: renderedShapes,
        },
        this.updateCanvas
      );
    }
  }

  updateCords(mousePos, shape = null) {
    if (shape) {
      this.setState({
        shapeBeingDragged: {
          id: shape.id,
          type: shape.type,
          startingPoint: {
            x: mousePos.x,
            y: mousePos.y,
          },
        },
      });
    } else {
      this.setState({
        shapeBeingDragged: {
          ...this.state.shapeBeingDragged,
          startingPoint: {
            x: mousePos.x,
            y: mousePos.y,
          },
        },
      });
    }
  }

  updateCanvas() {
    const { thumbnails } = this.props.recording;
    const { currentRenderedShapes, showVarLines } = this.state;

    const backgroundImg = new Image();
    backgroundImg.onload = () => {
      const canvas = this.canvas.current;
      const hitCanvas = this.hitCanvas.current;

      if (canvas) {
        let imgWidth = backgroundImg.width;
        let imgHeight = backgroundImg.height;
        const canvasContext = canvas.getContext('2d');
        const hitCanvasContext = hitCanvas.getContext('2d');

        canvas.width = imgWidth;
        canvas.height = imgHeight;

        hitCanvas.width = imgWidth;
        hitCanvas.height = imgHeight;
        canvasContext.drawImage(backgroundImg, 0, 0, imgWidth, imgHeight);

        if (currentRenderedShapes) {
          currentRenderedShapes.forEach((shape) => {
            if (shape.visible) {
              if (shape.type === LINE) {
                linesRendering(canvasContext, shape, showVarLines);
                if (!this.isShapeBeingDragged()) {
                  linesRendering(hitCanvasContext, shape, showVarLines, true);
                }
              }

              if (shape.type === AREA) {
                areasRendering(canvasContext, shape);
                if (!this.isShapeBeingDragged()) {
                  areasRendering(hitCanvasContext, shape, true);
                }
              }
            }
          });
        }
      }
    };
    backgroundImg.src = thumbnails[0].url;
  }

  moveShape(mousePos) {
    if (this.isShapeBeingDragged() && !this.isPointBeingDragged()) {
      const difference = {
        x: mousePos.x - this.state.shapeBeingDragged.startingPoint.x,
        y: mousePos.y - this.state.shapeBeingDragged.startingPoint.y,
      };

      const index = this.state.currentRenderedShapes.findIndex((shape) => {
        return (
          shape.id === this.state.shapeBeingDragged.id &&
          shape.type === this.state.shapeBeingDragged.type
        );
      });
      this.updateShapePosition(difference, index, mousePos);
    }
  }

  clearShapeBeingDragged() {
    this.setState({
      shapeBeingDragged: {},
    });
  }

  componentDidMount() {
    this.firstUpdateOfCurrentShapesInState();

    this.canvas.current.addEventListener('mousedown', (event) => {
      const mousePos = getMousePosition(event, this.canvas.current);
      const userClickedOnPoint = getPointBeingClicked(
        mousePos,
        this.state.currentRenderedShapes
      );

      if (userClickedOnPoint) {
        this.setPointBeingDragged(
          userClickedOnPoint.shape,
          userClickedOnPoint.index
        );
      }
      const shape = getShapeBeingClicked(
        mousePos,
        this.hitCanvas.current,
        this.state.colorsHash
      );
      if (shape) {
        this.updateCords(mousePos, shape);
        this.setState({
          renderedShapesHistory: [
            ...this.state.renderedShapesHistory,
            this.state.currentRenderedShapes,
          ],
        });
      }
    });

    this.canvas.current.addEventListener('mouseenter', (event) => {
      this.clearPointBeingDragged();
    });

    this.canvas.current.addEventListener('mousemove', (event) => {
      const mousePos = getMousePosition(event, this.canvas.current);
      this.moveShape(mousePos);
      this.updatePointPosition(mousePos);
    });

    this.canvas.current.addEventListener('mouseup', (event) => {
      this.clearShapeBeingDragged();
      this.clearPointBeingDragged();
    });

    this.canvas.current.addEventListener('mouseout', (event) => {
      this.clearShapeBeingDragged();
      this.clearPointBeingDragged();
    });

    document.addEventListener('keydown', this.keyboard.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyboard.bind(this));
  }

  keyboard(event) {
    if (event.ctrlKey === true) {
      switch (event.key) {
        case 'z':
          this.stepBack();
          break;
        default:
        // do nothing
      }
    }
  }

  render() {
    const { currentRenderedShapes, changesDoneButNotSaved, showVarLines } =
      this.state;
    const {
      keysAndValuesInUse,
      recording,
      taxonomiesInUse,
      updateEditRecordingPageState,
      loading,
    } = this.props;

    return (
      <Fragment>
        <CanvasContainer>
          <CanvasColumn>
            <canvas
              ref={this.canvas}
              style={canvasStyle}
              width="150"
              height="150"
              className={Classes.ELEVATION_3}
            />
            <canvas ref={this.hitCanvas} style={{ display: 'none' }} />
            <CreateStandardElems
              recording={recording}
              createNewShape={this.createNewShape.bind(this)}
              updateEditRecordingPageState={updateEditRecordingPageState}
              handleSaveChanges={this.handleSaveChanges.bind(this)}
            />
            <UndoButtonContainer>
              <Tooltip position={Position.TOP_RIGHT} content={'CTRL+Z'}>
                <Button
                  onClick={this.stepBack.bind(this)}
                  disabled={this.state.renderedShapesHistory.length <= 0}
                >
                  Undo
                </Button>
              </Tooltip>
            </UndoButtonContainer>
            <EditButton
              changesDoneButNotSaved={changesDoneButNotSaved}
              handleSaveChanges={this.handleSaveChanges.bind(this)}
            />
          </CanvasColumn>
          <MenuColumn>
            <WrapperMenuEditShapes className={Classes.ELEVATION_3}>
              <MenuEditShapes
                currentRenderedShapes={currentRenderedShapes}
                changeAreaIgnoreType={this.changeAreaIgnoreType.bind(this)}
                addNewPoint={this.addNewPoint.bind(this)}
                deletePoint={this.deletePoint.bind(this)}
                changeShapeName={this.changeShapeName.bind(this)}
                setShapeColor={this.setShapeColor.bind(this)}
                deleteShape={this.deleteShape.bind(this)}
                createNewShape={this.createNewShape.bind(this)}
                recording={recording}
                changesDoneButNotSaved={changesDoneButNotSaved}
                setChangesDoneButNotSaved={this.setChangesDoneButNotSaved.bind(
                  this
                )}
                changeLineArrowDirection={this.changeLineArrowDirection.bind(
                  this
                )}
                copyShape={this.copyShape.bind(this)}
                taxonomiesInUse={taxonomiesInUse}
                keysAndValuesInUse={keysAndValuesInUse}
                saveNewLineVar={this.saveNewLineVar.bind(this)}
                handleShowVarLines={this.handleShowVarLines.bind(this)}
                showVarLines={showVarLines}
                updateEditRecordingPageState={updateEditRecordingPageState}
                toggleShapeVisibility={this.toggleShapeVisibility.bind(this)}
                loading={loading}
              />
            </WrapperMenuEditShapes>
          </MenuColumn>
        </CanvasContainer>
      </Fragment>
    );
  }
}

export default EditShapes;
