// @flow

import { isEqual } from 'lodash';
import {
  IN_ACTION,
  OUT_ACTION,
  IN_AND_OUT_ACTION,
  IN,
  OUT,
  IN_OUT
} from '../constants/line-context';

export type directionT = 'in' | 'out';
export type crossingTypeT = directionT | 'in_out';
export type contextTypeT =
  | 'location-entry'
  | 'location-pass-by'
  | 'within-location-movement'
  | 'within-location-entry'
  | 'within-location-pass-by';
export type metadataItem = {
  key: string,
  value: string
};

export type actionItemBaseT = {
  direction: directionT,
  crossed: boolean
};

export type actionItemT = actionItemBaseT & {
  lineId: string
};

export type ActionsPair = [actionItemT, actionItemT];
export type actionsT = Array<ActionsPair>;

export type t = {
  id?: string,
  type: contextTypeT,
  withinLocationMovementKey?: string,
  actions: actionsT,
  recording?: string,
  createdAt?: string,
  metadata?: Array<metadataItem>
};

export type collectionT = Array<t>;

export const sortActions = (
  actions: Array<actionItemBaseT>
): Array<actionItemBaseT> => {
  return actions.sort((a, b) => (a.direction > b.direction ? 1 : -1));
};

const getActionsWithoutId = (actions: ActionsPair): Array<actionItemBaseT> => {
  return actions.map(({ direction, crossed }) => {
    return {
      direction,
      crossed
    };
  });
};

export const addIdToActionsBase = (
  lineId: string,
  actions: [actionItemBaseT, actionItemBaseT]
): ActionsPair => {
  const actionsWithId = actions.map(actionBase => {
    const action = {
      ...actionBase,
      lineId
    };
    return action;
  });

  return [actionsWithId[0], actionsWithId[1]];
};

export const getActionType = (actions: ActionsPair): ?crossingTypeT => {
  const actionsWithoutId = getActionsWithoutId(actions);

  if (isEqual(sortActions(actionsWithoutId), sortActions(IN_ACTION))) {
    return IN;
  } else if (isEqual(sortActions(actionsWithoutId), sortActions(OUT_ACTION))) {
    return OUT;
  } else if (
    isEqual(sortActions(actionsWithoutId), sortActions(IN_AND_OUT_ACTION))
  ) {
    return IN_OUT;
  }

  return undefined;
};

export const getLineId = (actions: ActionsPair): string => {
  return actions[0].lineId;
};

export const validateAction = ({
  lineId,
  crossed,
  direction
}: actionItemT): boolean => {
  return (
    !!lineId &&
    typeof crossed === 'boolean' &&
    !!direction &&
    [IN, OUT, IN_OUT].includes(direction)
  );
};

export const validateActions = (actions: [Object, Object]): boolean => {
  return (
    !!actions &&
    actions.length > 0 &&
    !!actions[0] &&
    !!actions[1] &&
    validateAction(actions[0]) &&
    validateAction(actions[1])
  );
};

export const validateAllActions = (actions: Array<any>): boolean => {
  let isValid = true;

  actions.forEach(action => {
    const isActionsValid = validateActions(action);
    if (!isActionsValid) {
      isValid = false;
    }
  });

  return isValid;
};

export const create = (object: Object): ?t => {
  const {
    actions,
    createdAt,
    id,
    recording,
    type,
    withinLocationMovementKey,
    metadata
  } = object;
  const isValidObject = actions && type;

  if (!isValidObject) return undefined;

  const lineContext = {
    actions,
    createdAt,
    id,
    recording,
    type,
    withinLocationMovementKey,
    metadata
  };
  return lineContext;
};
