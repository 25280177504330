//Colors
export const LIGHT_GRAY_BLUE = '#eff3f6';
export const NORMAL_GRAY_BLUE = '#CFD5D9';
export const DARK_ENGLISH_RED = '#C31919';

export const BLACK = 'rgb(0,0,0)';
export const WHITE = 'rgb(255,255,255)';
export const RED = 'rgb(255,0,0)';
export const BLUE = 'rgb(0,0,255)';
export const GREEN = 'rgb(0,255,0)';
export const YELLOW = 'rgb(255,255,0)';
export const RED_TRANSPARENT = 'rgba(255,0,0,0.1)';
export const BLUE_TRANSPARENT = 'rgba(0,0,255,0.1)';
export const GREEN_TRANSPARENT = 'rgba(0,255,0,0.1)';
export const YELLOW_TRANSPARENT = 'rgba(255,255,0,0.1)';
