export function apiObjMaker(arrayOfShapes, recording) {
  const stateElmsObjDefault = {
    areas: {
      ignore: {},
      trigger: {}
    },
    lines: {}
  };

  // ensure arcs aren't overwritten on save
  if (recording.statElems.arcs)
    stateElmsObjDefault.arcs = recording.statElems.arcs;

  const newStatElems = arrayOfShapes.reduce((finalObject, shape) => {
    if (shape.type === 'area') {
      if (shape.isIgnoreArea) {
        finalObject.areas.ignore[shape.id] = {
          color: [0, 0, 0],
          ...shape.points
        };
      } else {
        finalObject.areas.trigger[shape.id] = {
          color: [0, 0, 0],
          ...shape.points
        };
      }
    } else if (shape.type === 'line') {
      const { id, points } = shape;
      const { x0, x1, y0, y1, inverted, var: varLine } = points;
      finalObject.lines[id] = {
        inverted,
        var: varLine,
        x0,
        x1,
        y0,
        y1
      };
    }
    return finalObject;
  }, stateElmsObjDefault);

  return newStatElems;
}
