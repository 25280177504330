import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { recordings } from '../../../services/api';
import Navigation from '../../navigation';
import Header from '../../recording-header';
import { Page } from '../../../styles/templates';
import { uniq, flatten } from 'lodash';
import EditShapes from '../../edit-shapes';
import SelectedRecordingInfo from '../../selected-recording-info';
import { errorToast } from '../../../utils/toaster';
import {
  WITHIN_LOCATION_ENTRY,
  WITHIN_LOCATION_PASS_BY,
} from '../../../constants/line-context';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const CoordsEditorContainer = styled.div`
  padding: 0 1em;
  margin: 0;
`;
const getRecording = async (recId) => recordings.get(recId);
const listRecordings = async () =>
  recordings.list({
    include: 'areaContexts,locationDetails,organisationDetails',
  });

const EditRecordingPage = () => {
  const [loading, setLoading] = useState(true);
  const [recording, setRecording] = useState(null);
  const [recordings, setRecordings] = useState([]);
  const [taxonomiesInUse, setTaxonomiesInUse] = useState([]);
  const [keysAndValuesInUse, setKeysAndValuesInUse] = useState([]);
  const { recId } = useParams();

  const updateEditRecordingPageState = async () => {
    try {
      const recording = await getRecording(recId);
      if (!recording) {
        setLoading(false);
        return false;
      }
      setRecording(recording);

      let allRecordings = recordings;
      if (allRecordings.length === 0) {
        allRecordings = await listRecordings();
      }
      if (allRecordings.length > 0) {
        const recordingsForOrg = allRecordings.filter((r) => {
          if (r.organisation && recording.organisation) {
            return r.organisation._id === recording.organisation._id;
          }
          return false;
        });
        const allAreaContexts = flatten(
          recordingsForOrg.map((r) => r.areaContexts)
        ).filter((ac) => !!ac);
        const allLineContexts = flatten(
          recordingsForOrg.map((r) => r.lineContexts)
        ).filter((ac) => !!ac);
        const uniqContexts = uniq(allAreaContexts.map((ac) => ac.taxonomy));
        const allContexts = allLineContexts
          .filter(
            (lineContext) =>
              lineContext.type === WITHIN_LOCATION_ENTRY ||
              lineContext.type === WITHIN_LOCATION_PASS_BY
          )
          .reduce(
            (finalKeysAndValues, lineContext) => {
              lineContext.metadata.forEach(({ key, value }) => {
                finalKeysAndValues.keys = uniq([
                  ...finalKeysAndValues.keys,
                  key,
                ]);
                finalKeysAndValues.values = uniq([
                  ...finalKeysAndValues.values,
                  value,
                ]);
              });
              return finalKeysAndValues;
            },
            {
              keys: [],
              values: [],
            }
          );
        setTaxonomiesInUse(uniqContexts);
        setKeysAndValuesInUse(allContexts);
        setRecording(recording);
        setRecordings(allRecordings);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      errorToast({ message: e });
    }
  };
  const updateEditRecordingPage = useCallback(updateEditRecordingPageState, [
    recording,
  ]);

  useEffect(() => {
    if (!recording) updateEditRecordingPage();
  }, [recording, updateEditRecordingPage]);

  return (
    <Page>
      <Navigation />
      {recordings && <Header recordings={recordings} />}
      <CoordsEditorContainer>
        {recording && (
          <Fragment>
            <SelectedRecordingInfo recording={recording} />
            <EditShapes
              taxonomiesInUse={taxonomiesInUse}
              recording={recording}
              keysAndValuesInUse={keysAndValuesInUse}
              updateEditRecordingPageState={updateEditRecordingPage}
              loading={loading}
            />
          </Fragment>
        )}
      </CoordsEditorContainer>
    </Page>
  );
};

export default EditRecordingPage;
