import styled from 'styled-components';
import { LIGHT_GRAY_BLUE } from '../../styles/constants';

export const Row = styled.div`
  width: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'transparent'};
  padding: ${({ padding }) => (padding ? padding : '0.5em 0')};
  display: flex;
  border-bottom: 1px solid ${LIGHT_GRAY_BLUE};
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'flex-start'};
`;

export const Column = styled.div`
  flex: ${({ flex }) => flex};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: 0 1rem;
  gap: 0.5rem;
`;

Column.defaultProps = {
  justifyContent: 'left'
};

export const StackedColumn = styled.div`
  flex: ${({ flex }) => flex};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const col1Flex = 1;
export const col2Flex = 1;
export const col3Flex = 4;
export const col4Flex = 1;
