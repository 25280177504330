import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PreInitLoading from '../../components/pages/pre-init-loading';
import { getAuth0Client } from '../../services/auth0';

class Authenticated extends Component {
  state = {
    auth0CheckCompleted: false,
    isAuthenticated: false
  };

  async componentDidMount() {
    const auth0Client = getAuth0Client();
    const isAuthenticated = await auth0Client.isAuthenticated();
    this.setState({ auth0CheckCompleted: true, isAuthenticated });
  }

  render() {
    const { children } = this.props;
    const { auth0CheckCompleted, isAuthenticated } = this.state;

    if (auth0CheckCompleted && !isAuthenticated)
      return <Redirect to="/login" />;

    if (auth0CheckCompleted && isAuthenticated) return children;

    // user refrsh is still in progres....
    return <PreInitLoading />;
  }
}

export const AuthenticatedRoute = ({ path, component: Component, ...rest }) => (
  <Route
    {...rest}
    to={path}
    component={props => (
      <Authenticated>
        <Component {...props} />
      </Authenticated>
    )}
  />
);
