import styled from 'styled-components';
import { LIGHT_GRAY_BLUE } from './constants';

export const Page = styled.div`
  min-height: 100vh;
  height: 100%;
  min-width: 100%;
  background-color: ${LIGHT_GRAY_BLUE};
  box-sizing: border-box;
`;

export const PopOverContainer = styled.div`
  display: block;
  width: 300px;
  height: auto;
`;
