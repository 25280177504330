import React, { useState, useEffect, useRef } from 'react';
import {
  Drawer,
  FormGroup,
  InputGroup,
  Switch,
  Button,
  Callout,
  HTMLSelect
} from '@blueprintjs/core';
import styled from 'styled-components';
import { authPkgConfig } from '@auravisionlabs/aura-pkg-auth-service';
import * as AuthAPI from '../../../services/auth-api';

const FormWrapper = styled.form`
  width: 90%;
  margin: 2em auto;
`;

export default ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const nameInput = useRef(null);
  const [resource, setResource] = useState('');
  const [ioTCommandNameForResource, setIoTCommandNameForResource] = useState(
    ''
  );
  const [canCreate, setCanCreate] = useState(false);
  const [canRead, setCanRead] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [visibility, setVisibility] = useState('own org');
  const [auraAdminOnly, setAuraAdminOnly] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (nameInput && nameInput.current) nameInput.current.focus();
      setResponse({});
    }
  }, [isOpen]);

  const formSubmit = async e => {
    e.preventDefault();

    const name = nameInput.current.value;

    setLoading(true);
    setResponse({});

    try {
      const newCapability = {
        name,
        resource,
        canCreate,
        canRead,
        canUpdate,
        canDelete,
        visibility,
        auraAdminOnly
      };
      // add in the IOT command name given if required
      if (
        newCapability.resource ===
        authPkgConfig.resources.deviceIOTSingleCommand
      ) {
        newCapability.resource = `${newCapability.resource}${ioTCommandNameForResource}`;
      }
      await AuthAPI.capabilities.create(newCapability);
      setResponse({ type: 'success', text: 'Capability added' });
    } catch (e) {
      setResponse({
        type: 'warning',
        text: 'There was an error'
      });
    }

    setLoading(false);
  };

  return (
    <Drawer
      icon="plus"
      title="Add a new capability"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormWrapper onSubmit={formSubmit}>
        <FormGroup label="Capability Name" labelFor="text-input">
          <InputGroup id="text-input" inputRef={nameInput} required />
        </FormGroup>
        <FormGroup label="Resource" labelFor="resource">
          <HTMLSelect
            onChange={e => setResource(e.target.value)}
            options={Object.values(authPkgConfig.resources)}
            value={resource}
            id="resource"
          />
        </FormGroup>
        {resource === authPkgConfig.resources.deviceIOTSingleCommand && (
          <FormGroup
            label="Name of IoT Command to give access to"
            labelFor="iotCommandName"
          >
            <InputGroup
              onChange={e => setIoTCommandNameForResource(e.target.value)}
              value={ioTCommandNameForResource}
              id="iotCommandName"
            />
          </FormGroup>
        )}
        <Switch
          label="Can Create"
          checked={canCreate}
          onChange={() => setCanCreate(!canCreate)}
        />
        <Switch
          label="Can Read"
          checked={canRead}
          onChange={() => setCanRead(!canRead)}
        />
        <Switch
          label="Can Update"
          checked={canUpdate}
          onChange={() => setCanUpdate(!canUpdate)}
        />
        <Switch
          label="Can Delete"
          checked={canDelete}
          onChange={() => setCanDelete(!canDelete)}
        />
        <FormGroup label="Visibility" labelFor="resource">
          <HTMLSelect
            onChange={e => setVisibility(e.target.value)}
            options={['all org', 'own org', 'personal']}
            value={visibility}
            id="resource"
          />
        </FormGroup>
        <Switch
          label="Aura Admin Only"
          checked={auraAdminOnly}
          onChange={() => setAuraAdminOnly(!auraAdminOnly)}
        />

        <Button type="submit" loading={loading} intent="primary">
          Create capability
        </Button>
        {response.type && (
          <Callout intent={response.type}>{response.text}</Callout>
        )}
      </FormWrapper>
    </Drawer>
  );
};
