import React from 'react';
import {
  Checkbox,
  Popover,
  Menu,
  MenuItem,
  Button,
  Spinner
} from '@blueprintjs/core';

const commonConf = {
  resizable: true,
  editable: true,
  sortable: true
};

export const userModelColumns = [
  { key: 'id', name: 'Id', ...commonConf, width: 50 },
  { key: 'fullName', name: 'Name', ...commonConf },
  { key: 'email', name: 'Email', ...commonConf },
  { key: 'status', name: 'Status', ...commonConf, width: 75 },
  { key: 'type', name: 'Type', ...commonConf, width: 75 },
  { key: 'auth0Id', name: 'Auth0 Id', ...commonConf },
  { key: 'primaryOrgName', name: 'Current active org', ...commonConf },
  { key: 'ownerOrgName', name: 'Owner org', ...commonConf },
  {
    key: 'adopted',
    name: 'Adopted',
    ...commonConf,
    width: 75,
    formatter: row => <Checkbox checked={!!row.value} />
  },
  {
    key: 'usersOrgs',
    name: 'Other orgs',
    resizable: true,
    formatter: ({ value }) => {
      if (!value) return <Spinner size={12} />;
      if (typeof value === 'string') return <div>{value}</div>;
      if (value.length > 1) {
        return (
          <Popover
            content={
              <Menu>
                {value.map(org => (
                  <MenuItem
                    key={org.id}
                    shouldDismissPopover={false}
                    text={org.name}
                  />
                ))}
              </Menu>
            }
          >
            <Button rightIcon="caret-down" text={`${value.length} orgs`} />
          </Popover>
        );
      } else {
        return <div>-</div>;
      }
    }
  },
  { key: 'createdAt', name: 'Created', ...commonConf },
  { key: 'updatedAt', name: 'Last updated', ...commonConf },
  {
    key: 'actions',
    name: 'Actions',
    resizable: true,
    editable: true,
    width: 220
  }
];

export const userModelColumnsNoActions = userModelColumns.filter(
  col => col.key !== 'actions'
);

export const userModelColumnsNoActionsNoUsersOrgs = userModelColumns.filter(
  col => col.key !== 'actions' && col.key !== 'usersOrgs'
);
