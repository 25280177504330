import React from 'react';
import styled from 'styled-components';
import { Tag, Intent, Elevation, Card } from '@blueprintjs/core';

const Wrapper = styled.div`
  margin-bottom: 1em;
`;

const SelectedRecordingInfo = ({ recording }) => (
  <Wrapper>
    <Card elevation={Elevation.ONE}>
      <Tag large intent={Intent.PRIMARY}>
        Name: {recording.name}
      </Tag>
      <Tag large intent={Intent.WARNING}>
        Org: {recording.organisation && recording.organisation.name}
      </Tag>
      <Tag large intent={Intent.SUCCESS}>
        Loc: {recording.location && recording.location.name}
      </Tag>
    </Card>
  </Wrapper>
);

export default SelectedRecordingInfo;
