import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Button, Spinner, Callout } from '@blueprintjs/core';
import ReactDataGrid from 'react-data-grid';

import Navigation from '../../navigation';
import UsersHeader from '../../users-header';
import * as AuthAPI from '../../../services/auth-api';
import { capabilityModelColumns } from '../users/capability-model-columns';
import CreateCapability from '../../users-drawers/create-capability';
import { genericSorter } from '../../../utils/dataGridTableSorting';

const Row = styled.div`
  width: 95%;
  max-width: 1600px;
  margin: 1em auto;
`;

const EmptyRowWrapper = styled.div`
  padding: 5em 3em;
  background: #f3f3f3;
  border-radius: 3px;
  color: #555;
`;

class UsersCapabilitiesPage extends Component {
  state = {
    capabilities: [],
    capabilitiesLoading: false,
    isCreateCapabilityDrawerOpen: false
  };

  componentDidMount() {
    this.fetchCapabilities();
  }

  async fetchCapabilities() {
    try {
      this.setState({ capabilitiesLoading: true });
      const { data: capabilities } = await AuthAPI.capabilities.list(true);

      this.setState({
        capabilities,
        capabilitiesLoading: false
      });
    } catch (e) {
      this.setState({ capabilitiesLoading: false });
      console.error(e);
    }
  }

  setIsCreateCapabilityDrawerOpen(newValue) {
    const isCreateCapabilityDrawerOpen =
      typeof newValue === 'boolean'
        ? newValue
        : !this.state.isCreateCapabilityDrawerOpen;

    this.setState({
      isCreateCapabilityDrawerOpen
    });

    // the drawer just closed - refetch the capabilities list
    if (newValue === false) {
      this.fetchCapabilities();
    }
  }

  render() {
    const {
      capabilities = [],
      capabilitiesLoading,
      isCreateCapabilityDrawerOpen
    } = this.state;

    const columns = capabilityModelColumns;

    const showEmptyRowWrapper = capabilitiesLoading || !capabilities.length > 0;

    return (
      <Fragment>
        <Navigation />
        <Row>
          <UsersHeader />
        </Row>
        <Row>
          {showEmptyRowWrapper && (
            <EmptyRowWrapper>
              {capabilitiesLoading ? (
                <Spinner />
              ) : (
                <h3>No capabilities found</h3>
              )}
            </EmptyRowWrapper>
          )}

          {!showEmptyRowWrapper && (
            <ReactDataGrid
              columns={columns}
              rowGetter={i => capabilities[i]}
              rowsCount={capabilities.length}
              minHeight={600}
              getCellActions={(col, cap) =>
                col.key === 'actions' ? this.getActions(cap.id) : null
              }
              enableCellSelect={true}
              onGridSort={(column, sortDir) => {
                this.setState({
                  capabilities: genericSorter(capabilities, column, sortDir)
                });
              }}
            />
          )}
        </Row>

        <Row>
          <Callout>
            <Button
              text="Create new capability"
              icon="plus"
              intent="primary"
              onClick={() => this.setIsCreateCapabilityDrawerOpen(true)}
            />
          </Callout>
        </Row>

        <CreateCapability
          isOpen={isCreateCapabilityDrawerOpen}
          onClose={() => this.setIsCreateCapabilityDrawerOpen(false)}
          title="Create a new capability"
        />
      </Fragment>
    );
  }
}

export default UsersCapabilitiesPage;
