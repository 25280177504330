// @flow

import { AUTH_API_BASE } from '../config/vars';
import { call } from './http';

export const users = {
  list: (orgId: string): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/users?query[ownerOrgId]=${orgId}&pageSize=500`,
      fetchOptions: { method: 'GET' }
    });
  },
  listAdopted: (orgId: string): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/adopted-users?query[ownerOrgId]=${orgId}&pageSize=500`,
      fetchOptions: { method: 'GET' }
    });
  },
  delete: (userId: string): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/users/${userId}`,
      fetchOptions: { method: 'DELETE' },
      responseExpected: false
    });
  },
  update: (userId: string, userObject: Object): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/users/${userId}`,
      fetchOptions: { method: 'PUT', body: JSON.stringify(userObject) }
    });
  },
  organisationsForUser: (userId: string, silent: boolean = false): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/users/${userId}/organisations`,
      fetchOptions: { method: 'GET' },
      otherOptions: {
        disableErrorToasts: silent,
        disable401Toast: silent,
      }
    });
  },
  rolesForUser: (userId: string, orgId: string): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/users/${userId}/roles?query[orgId]=${orgId}`,
      fetchOptions: { method: 'GET' },
    });
  },
  addRole: (userId: string, orgId: string, roleId: string): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/users/${userId}/roles`,
      fetchOptions: {
        method: 'POST',
        body: JSON.stringify({ orgId, roleId: Number(roleId) })
      }
    });
  },
  removeRole: (
    userId: string,
    orgId: string,
    roleId: string
  ): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/users/${userId}/roles/${roleId}?query[orgId]=${orgId}`,
      fetchOptions: { method: 'DELETE' },
      responseExpected: false
    });
  }
};

export const roles = {
  list: (): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/roles?pageSize=100&orderBy=id`,
      fetchOptions: { method: 'GET' }
    });
  }
};

export const adminRoles = {
  list: (silent: boolean = false): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/admin-roles?pageSize=100&orderBy=id`,
      fetchOptions: { method: 'GET' },
      otherOptions: {
        disableErrorToasts: silent,
        disable401Toast: silent,
      }
    });
  }
};

type orgT = {
  id: string,
  name: string
};

export const invite = {
  send: (email: string, roleIds: string[], org: orgT): Promise<Object> => {
    const body = JSON.stringify({
      email,
      roles: roleIds,
      invitingOrgName: org.name,
      orgToInviteTo: org.id
    });

    return call({
      path: `${AUTH_API_BASE}/api/v1/invite-user`,
      fetchOptions: { method: 'POST', body },
      responseExpected: false
    });
  }
};

const generateAllOrgsQuery = allOrgs => {
  return allOrgs.reduce(
    (acc, org, index) =>
      `${acc}${index === 0 ? '' : '&'}query[orgId]=${org.id}`,
    ''
  );
};

export const roles_users = {
  list: (roleId: string, allOrgs: orgT[]): Promise<Object> => {
    const allOrgsQs = generateAllOrgsQuery(allOrgs);
    return call({
      path: `${AUTH_API_BASE}/api/v1/roles/${roleId}/users?pageSize=500&${allOrgsQs}&orderBy=id`,
      fetchOptions: { method: 'GET' }
    });
  }
};

export const adminRoles_users = {
  list: (roleId: string, allOrgs: orgT[]): Promise<Object> => {
    const allOrgsQs = generateAllOrgsQuery(allOrgs);
    return call({
      path: `${AUTH_API_BASE}/api/v1/admin-roles/${roleId}/users?pageSize=500&${allOrgsQs}&orderBy=id`,
      fetchOptions: { method: 'GET' }
    });
  }
};

export const adminRoles_capabilities = {
  list: (roleId: string): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/admin-roles/${roleId}/capabilities?pageSize=500&&orderBy=id`,
      fetchOptions: { method: 'GET' }
    });
  },
  create: (roleId: string, capabilityId: string): Promise<Object> => {
    const body = JSON.stringify({
      capabilityId: Number(capabilityId)
    });
    return call({
      path: `${AUTH_API_BASE}/api/v1/admin-roles/${roleId}/capabilities`,
      fetchOptions: { method: 'POST', body }
    });
  }
};

type newCapabilityT = {
  name: string,
  resource: string,
  canCreate: boolean,
  canRead: boolean,
  canUpdate: boolean,
  canDelete: boolean,
  visibility: string,
  auraAdminOnly: boolean
};
export const capabilities = {
  list: (silent: boolean = false): Promise<Object> => {
    return call({
      path: `${AUTH_API_BASE}/api/v1/capabilities?pageSize=500&&orderBy=id`,
      fetchOptions: { method: 'GET' },
      otherOptions: {
        disableErrorToasts: silent,
        disable401Toast: silent,
      }
    });
  },
  create: (capability: newCapabilityT): Promise<Object> => {
    const body = JSON.stringify(capability);
    return call({
      path: `${AUTH_API_BASE}/api/v1/capabilities`,
      fetchOptions: { method: 'POST', body }
    });
  }
};
