// @flow

import createAuth0Client from '@auth0/auth0-spa-js';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '../config/vars';

let auth0Client;

export const auth0Init = async (): Promise<empty> => {
  auth0Client = await createAuth0Client({
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    audience: 'https://api-core.auravision.ai'
  });
  return auth0Client;
};

export const getAuth0Client = (): empty => auth0Client;
