import React from 'react';
import { Menu, MenuItem, Button, Popover } from '@blueprintjs/core';

export default ({ onEdit, onRolesEdit, onDelete }) => (
  <Popover
    content={
      <Menu>
        <MenuItem text="Edit User" onClick={onEdit} />
        <MenuItem text="View/Edit Roles" onClick={onRolesEdit} />
        <MenuItem intent="danger" text="Delete User" onClick={onDelete} />
      </Menu>
    }
  >
    <Button rightIcon="caret-down" text="Actions" />
  </Popover>
);
