import React from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { Navbar, Button, Alignment } from '@blueprintjs/core';

const HeaderMain = styled(Navbar)`
  margin-bottom: 1em;
`;

const ButtonLink = ({ text, to }) => {
  let match = useRouteMatch({
    path: to,
    exact: true
  });

  return (
    <Link to={to}>
      <Button active={match} text={text} intent="success" />
    </Link>
  );
};

export default () => (
  <HeaderMain>
    <Navbar.Group align={Alignment.LEFT}>
      <Navbar.Heading>
        <ButtonLink text="Users List" to="/users" />
        <ButtonLink text="Roles List" to="/users/roles" />
        <ButtonLink text="Capabilities List" to="/users/capabilities" />
      </Navbar.Heading>
    </Navbar.Group>
  </HeaderMain>
);
