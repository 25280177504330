import React from 'react';
import { Provider } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import history from './history';
import store from './store';
import Auth0Wrapper from './auth0Wrapper';

import '../styles/reset.css';

//pages
import AllRecordingsPage from '../containers/all-recordings';
import EditRecordingPage from '../components/pages/edit-recording';
import LoginPage from '../components/pages/login';
import LogoutPage from '../components/pages/logout';
import UsersPage from '../components/pages/users';
import UsersRolesPage from '../components/pages/users-roles';
import UsersCapabilitiesPage from '../components/pages/users-capabilities';
// import DevicesPage from '../components/pages/devices';
import Page404 from '../components/pages/404';

// components
import { AuthenticatedRoute } from './../components/authenticate';

// config
import { USE_SENTRY } from '../config/vars';

if (USE_SENTRY) {
  Sentry.init({
    dsn:
      'https://5337268db9fe477989daf78500f5707b@o135941.ingest.sentry.io/5338025'
  });
}

export default () => {
  return (
    <Auth0Wrapper>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Redirect to="/cameras" />}
            />
            <AuthenticatedRoute
              exact
              path="/cameras"
              component={AllRecordingsPage}
            />
            <Route exact path="/login" component={LoginPage} />
            <AuthenticatedRoute
              exact
              path="/cameras/edit/:recId"
              component={EditRecordingPage}
            />
            <Route exact path="/logout" component={LogoutPage} />
            <AuthenticatedRoute exact path="/users" component={UsersPage} />
            <AuthenticatedRoute
              exact
              path="/users/roles"
              component={UsersRolesPage}
            />
            <AuthenticatedRoute
              exact
              path="/users/capabilities"
              component={UsersCapabilitiesPage}
            />
            {/* <AuthenticatedRoute exact path="/devices" component={DevicesPage} /> */}
            <Route component={Page404} />
          </Switch>
        </Router>
      </Provider>
    </Auth0Wrapper>
  );
};
