import React from 'react';
import styled from 'styled-components';
import {
  Intent,
  Classes,
  Button,
  Popover,
  Menu,
  Spinner,
  MenuItem,
  Icon,
} from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import {
  Row,
  Column,
  StackedColumn,
} from './table';
import { AREA } from '../../constants/shapes';
import { Select } from '@blueprintjs/select';

const StyledMenu = styled(Menu)`
  max-height: 200px;
  overflow-y: scroll;
`;

const filterPredicate = (query, item) => {
  if (item) {
    return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }
};

const EditingItemForm = ({
  item,
  currentRenderedShapes,
  taxonomiesInUse,
  cancelEditing,
  editItem,
  saveItem,
  setShapeColor,
  loading,
  loadingTaxonomies,
}) =>
  loading ? (
    <Spinner />
  ) : (
    <>
      <Row>
        <Column>
          <Popover>
            <Button
              text={item.type || 'Select a context type'}
              rightIcon="caret-down"
            />
            <Menu>
              <MenuItem
                text="taxonomy"
                onClick={(e) => editItem({ ...item, type: 'taxonomy' })}
              />
              <MenuItem
                text="location-entry"
                onClick={(e) => editItem({ ...item, type: 'location-entry' })}
              />
              <MenuItem
                text="location-dwell"
                onClick={(e) => editItem({ ...item, type: 'location-dwell' })}
              />
            </Menu>
          </Popover>
        </Column>
        <Column>
          <Popover>
            <Button
              text={item.areaId || 'Select an area ID'}
              rightIcon="caret-down"
            />
            <StyledMenu>
              {currentRenderedShapes
                .filter((shape) => shape.type === AREA)
                .map((shape, index) => (
                  <MenuItem
                    key={`${shape.id} - ${index}`}
                    onMouseEnter={() => setShapeColor(shape)}
                    onMouseLeave={() => setShapeColor({})}
                    text={shape.id}
                    onClick={(e) => editItem({ ...item, areaId: shape.id })}
                  />
                ))}
            </StyledMenu>
          </Popover>
        </Column>
        <StackedColumn style={{width: '100%'}}>
          {item.type === 'taxonomy' && (
            <Column style={{width: '100%'}}>
              <Select
                items={!loadingTaxonomies && taxonomiesInUse ? taxonomiesInUse.sort((a, z) => a - z) : ['Loading...']}
                itemPredicate={filterPredicate}
                itemRenderer={(taxonomy, { handleClick }) => (
                  <MenuItem
                    key={taxonomy}
                    text={taxonomy}
                    active={item.taxonomy === taxonomy}
                    onClick={handleClick}
                  />
                )}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={(taxonomy) => editItem({ ...item, taxonomy })}
              >
                <Button
                  text="Choose from existing taxonomies"
                  rightIcon="caret-down"
                />
              </Select>

              <input
                type="text"
                value={item.taxonomy || ''}
                width="80%"
                onChange={(e) =>
                  editItem({ ...item, taxonomy: e.target.value })
                }
                placeholder="Enter a taxonomy, format: `Category:Subcategory:Sub-sub-category`"
                className={Classes.INPUT}
                style={{ width: '100%', minWidth: '200px' }}
              />
              <Tooltip2
                content={
                  <>
                    Taxonomies are colon (:) separated to denote A hierarchy,
                    so enter a taxonomy in the format{' '}
                    <em>Category:Subcategory:Sub-sub-category</em>. A real
                    world example might be <em>Products:iPhones</em>
                  </>
                }
              >
                <Icon icon="help" />
              </Tooltip2>
            </Column>
          )}
        </StackedColumn>
      </Row>
      <Row justifyContent="flex-end">
        <Button intent={Intent.PRIMARY} onClick={(e) => saveItem(item)}>
          Save
        </Button>
        <Button intent={Intent.WARNING} onClick={cancelEditing}>
          Cancel
        </Button>
      </Row>
    </>
  );

export default EditingItemForm;
