import { Button } from '@blueprintjs/core';
import React, { useState } from 'react';
import { AREA, LINE } from '../../constants/shapes';
import { areaContext, lineContext } from '../../services/api';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  padding: 1rem;
`;

const saveAreaContext = async (
  context,
  recording,
  updateEditRecordingPageState
) => {
  const response = await areaContext.create(context, recording.id);

  if (response && response.id) {
    updateEditRecordingPageState();
  }
};

const saveLineContext = async (
  context,
  recording,
  updateEditRecordingPageState
) => {
  const response = await lineContext.create(context, recording.id);

  if (response && response.id) {
    updateEditRecordingPageState();
  }
};

export const CreateStandardElems = ({
  recording,
  createNewShape,
  updateEditRecordingPageState,
  handleSaveChanges,
}) => {
  const [loading, setLoading] = useState(false);

  let entryLineNumber = 1;
  let foundNext = false;
  while (!foundNext) {
    const found = recording.statElems.lines[`entry-${entryLineNumber}`];
    if (!found) {
      foundNext = true;
    } else {
      entryLineNumber += 1;
    }
  }

  let passerByLineNumber = 1;
  foundNext = false;
  while (!foundNext) {
    const found = recording.statElems.lines[`passer-by-${passerByLineNumber}`];
    if (!found) {
      foundNext = true;
    } else {
      passerByLineNumber += 1;
    }
  }

  let dwellAreaNumber = 1;
  foundNext = false;
  while (!foundNext) {
    const found = recording.statElems.areas.trigger[`dwell-${dwellAreaNumber}`];
    if (!found) {
      foundNext = true;
    } else {
      dwellAreaNumber += 1;
    }
  }

  return (
    <ButtonContainer>
      <Button
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await createNewShape(
            { name: `entry-${entryLineNumber}`, type: LINE },
            true
          );
          await handleSaveChanges();
          await saveLineContext(
            {
              recording,
              type: 'location-entry',
              actions: [
                [
                  {
                    lineId: `entry-${entryLineNumber}`,
                    direction: 'in',
                    crossed: true,
                  },
                  {
                    lineId: `entry-${entryLineNumber}`,
                    direction: 'out',
                    crossed: false,
                  },
                ],
              ],
              metadata: [],
            },
            recording,
            updateEditRecordingPageState
          );
          setLoading(false);
        }}
      >
        + Entry Line
      </Button>
      <Button
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await createNewShape(
            { name: `passer-by-${passerByLineNumber}`, type: LINE, varValue: 0 },
            true
          );
          await handleSaveChanges();
          await saveLineContext(
            {
              recording,
              type: 'location-pass-by',
              actions: [
                [
                  {
                    lineId: `passer-by-${passerByLineNumber}`,
                    direction: 'out',
                    crossed: true,
                  },
                  {
                    lineId: `passer-by-${passerByLineNumber}`,
                    direction: 'in',
                    crossed: false,
                  },
                ],
                [
                  {
                    lineId: `passer-by-${passerByLineNumber}`,
                    direction: 'out',
                    crossed: false,
                  },
                  {
                    lineId: `passer-by-${passerByLineNumber}`,
                    direction: 'in',
                    crossed: true,
                  },
                ],
              ],
              metadata: [],
            },
            recording,
            updateEditRecordingPageState
          );
          setLoading(false);
        }}
      >
        + Passer By Line
      </Button>
      <Button
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await createNewShape(
            { name: `dwell-${dwellAreaNumber}`, type: AREA },
            true
          );
          await handleSaveChanges();
          await saveAreaContext(
            {
              areaId: `dwell-${dwellAreaNumber}`,
              recording,
              type: 'location-dwell',
            },
            recording,
            updateEditRecordingPageState
          );
          setLoading(false);
        }}
      >
        + Dwell Area
      </Button>
    </ButtonContainer>
  );
};
