import React from 'react';
import styled from 'styled-components';
import { Spinner, Classes } from '@blueprintjs/core';
import { LIGHT_GRAY_BLUE } from '../../../styles/constants';

const Wrapper = styled.section`
  padding: 2em;
  background: ${LIGHT_GRAY_BLUE};
  width: 100%;
  text-align: center;
  height: 100vh;
`;

const PreInitLoading = () => (
  <Wrapper>
    <Spinner className={Classes.SMALL} />
  </Wrapper>
);

export default PreInitLoading;
