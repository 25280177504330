// @flow

import { cloneDeep } from 'lodash';
import {
  WITHIN_LOCATION_PASS_BY,
  WITHIN_LOCATION_ENTRY,
  WITHIN_LOCATION_MOVEMENT
} from '../constants/line-context';

import * as LineContextModel from '../models/line-context';
import type { editLineConfigT } from '../components/line-contexts/editing-item-form';

export const sanitizeQueryItem = (item: editLineConfigT): editLineConfigT => {
  const contextType = item.type;

  const sanitizedItem = cloneDeep(item);
  if (contextType !== WITHIN_LOCATION_MOVEMENT) {
    sanitizedItem.withinLocationMovementKey = undefined;
  }

  if (
    sanitizedItem.metadata &&
    (contextType === WITHIN_LOCATION_ENTRY ||
      contextType === WITHIN_LOCATION_PASS_BY)
  ) {
    const sanitizeMetadata = sanitizedItem.metadata.map(({ key, value }) => ({
      key,
      value
    }));

    sanitizedItem.metadata = sanitizeMetadata;
  } else {
    sanitizedItem.metadata = undefined;
  }

  return sanitizedItem;
};

export const transformLineContextInEditLineConfig = (
  lineContext: LineContextModel.t
): editLineConfigT => {
  const { actions } = lineContext;
  const editingConfigActions = actions.reduce((allActions, action) => {
    const crossingType = LineContextModel.getActionType(action);
    const lineId = LineContextModel.getLineId(action);

    if (lineId && crossingType) {
      allActions.push({
        lineId,
        crossingType
      });
    }
    return allActions;
  }, []);

  const editLineConfig = {
    ...lineContext,
    actions: editingConfigActions
  };

  return editLineConfig;
};
