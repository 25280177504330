// @flow

import type { contextTypeT } from '../../models/line-context';
import type { crossingTypeT } from '../../models/line-context';
import type { ActionsPair } from '../../models/line-context';
import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { Intent, Button, Spinner } from '@blueprintjs/core';
import { Row, Column } from './table';
import {
  IN_ACTION,
  OUT_ACTION,
  IN_AND_OUT_ACTION,
  IN,
  OUT,
  IN_OUT,
  WITHIN_LOCATION_MOVEMENT,
  WITHIN_LOCATION_ENTRY,
  WITHIN_LOCATION_PASS_BY,
} from '../../constants/line-context';
import { errorToast } from '../../utils/toaster';
import { sanitizeQueryItem } from '../../utils/lineContextHelpers';
import LineEditingMenu from './line-editing-menu';
import LineTypeMenu from './line-type-menu';
import WithinLocationOptions from './within-location-options';

import * as LineContextModel from '../../models/line-context';
import * as ShapeModel from '../../models/shape';
import * as RecordingModel from '../../models/recording';

export const Col: any = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export type editingConfigActionT = {
  lineId?: string,
  crossingType?: LineContextModel.crossingTypeT,
};

export type editLineConfigT = {
  id?: string,
  type: ?LineContextModel.contextTypeT,
  recording?: string,
  withinLocationMovementKey?: string,
  metadata?: Array<LineContextModel.metadataItem>,
  actions: Array<editingConfigActionT>,
};

type Props = {
  lineContexts: Array<LineContextModel.t>,
  currentRenderedShapes: Array<ShapeModel.t>,
  setShapeColor(shape: ShapeModel.t): void,
  cancelEditing(): void,
  loading: boolean,
  recording: RecordingModel.t,
  saveItem(context: LineContextModel.t): void,
  newItem?: editLineConfigT | null,
  handleBeingEditedLineContext(context: LineContextModel.t | null): void,
  keysAndValuesInUse?: {
    keys: string[],
    values: string[],
  },
};

type State = editLineConfigT;

class EditingItemForm extends Component<Props, State> {
  state: State = {
    id: undefined,
    type: undefined,
    recording: undefined,
    withinLocationMovementKey: undefined,
    metadata: undefined,
    actions: [],
  };

  componentDidMount() {
    const { recording, newItem } = this.props;

    if (newItem) {
      this.setState({
        ...newItem,
      });
    } else {
      this.setState({
        recording: recording.id,
      });
    }
  }

  handleWithinLocationMovementKey: (
    e: SyntheticInputEvent<HTMLInputElement>
  ) => void = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      withinLocationMovementKey: e.currentTarget.value,
    });
  };

  handleAddMetadataItem: () => void = () => {
    const { metadata = [] } = this.state;
    const newMetadataItem = {
      key: '',
      value: '',
    };
    const updatedMetadata = [...metadata, newMetadataItem];

    this.setState({
      metadata: updatedMetadata,
    });
  };

  handleDeleteMetadaItem: (index: number) => null | void = (index: number) => {
    const { metadata } = this.state;
    if (!metadata) return null;

    const updatedMetadata = metadata.filter((metadataItem, i) => i !== index);

    this.setState({
      metadata: updatedMetadata,
    });
  };

  handleEditMetadataItem: (
    value: string,
    index: number,
    editType: 'key' | 'value'
  ) => void = (value: string, index: number, editType: 'key' | 'value') => {
    const { metadata = [] } = this.state;
    const iterableMetadata = metadata.map((metadaItem) =>
      cloneDeep(metadaItem)
    );
    const updatedMetadata = iterableMetadata.map((metadataItem, i) => {
      if (i === index) {
        metadataItem[editType] = value;
      }
      return metadataItem;
    });

    this.setState({
      metadata: updatedMetadata,
    });
  };

  addNewLine: () => void = () => {
    const { actions } = this.state;
    const defaultAction = {
      lineId: undefined,
      crossingType: undefined,
    };
    const newActions = [...actions, defaultAction];

    this.setState({
      actions: newActions,
    });
  };

  crossingTypeFormatter: (
    editingConfigAction: editingConfigActionT
  ) => void | ActionsPair | Array<empty> = (
    editingConfigAction: editingConfigActionT
  ) => {
    const { lineId, crossingType } = editingConfigAction;

    if (crossingType && typeof lineId === 'string') {
      switch (crossingType) {
        case IN:
          return LineContextModel.addIdToActionsBase(lineId, IN_ACTION);
        case OUT:
          return LineContextModel.addIdToActionsBase(lineId, OUT_ACTION);
        case IN_OUT:
          return LineContextModel.addIdToActionsBase(lineId, IN_AND_OUT_ACTION);
        default:
          return [];
      }
    }
  };

  handleCrossingType: (crossingType: crossingTypeT, index: number) => void = (
    crossingType: LineContextModel.crossingTypeT,
    index: number
  ) => {
    const { actions } = this.state;
    const newActions = [...actions];

    newActions[index] = {
      ...newActions[index],
      crossingType,
    };

    this.setState({
      actions: newActions,
    });
  };

  handleSelectLineId: (lineId: string, index: number) => void = (
    lineId: string,
    index: number
  ) => {
    const { actions } = this.state;
    const newActions = [...actions];

    newActions[index] = {
      ...newActions[index],
      lineId,
    };

    this.setState({
      actions: newActions,
    });
  };

  handleLineType: (type: contextTypeT) => void = (
    type: LineContextModel.contextTypeT
  ) => {
    this.setState({
      type,
    });
  };

  validateContext(context: Object): boolean {
    let doThrow = false;

    if (!context.type) {
      errorToast({ message: 'Missing a type' });
      doThrow = true;
    }
    if (
      context.type === WITHIN_LOCATION_MOVEMENT &&
      !context.withinLocationMovementKey
    ) {
      errorToast({ message: 'Missing a withinLocationMovementKey' });
      doThrow = true;
    }
    if (
      !context.actions ||
      context.actions.length === 0 ||
      !LineContextModel.validateAllActions(context.actions)
    ) {
      errorToast({ message: 'Missing Lines' });
      doThrow = true;
    }
    if (
      context.type === WITHIN_LOCATION_ENTRY ||
      context.type === WITHIN_LOCATION_PASS_BY
    ) {
      if (!context.metadata || context.metadata.length === 0) {
        errorToast({ message: 'Missing Metadata' });
        doThrow = true;
      } else {
        const notValueOrKeyInMetadata = context.metadata.some(
          ({ key, value }) => !key || !value
        );

        if (notValueOrKeyInMetadata) {
          errorToast({ message: 'Missing key or value in metadata' });
          doThrow = true;
        }
      }
    }

    return doThrow;
  }

  saveLineContext: () => null | void = () => {
    const { saveItem } = this.props;
    const {
      id,
      actions,
      type,
      recording,
      withinLocationMovementKey,
      metadata,
    } = this.state;

    const newActions = actions.map((action) =>
      this.crossingTypeFormatter(action)
    );

    const item = {
      id,
      type,
      withinLocationMovementKey,
      recording,
      actions: newActions,
      metadata,
    };

    // $FlowFixMe -> the state is editLineConfigT type and this function receives editLineConfigT, but flows is still complaining
    const sanitizedItem = sanitizeQueryItem(item);

    const errors = this.validateContext(sanitizedItem);
    if (errors) return null;

    const lineContext = LineContextModel.create(sanitizedItem);
    if (lineContext) {
      saveItem(lineContext);
    }
  };

  handleDeleteAction: (index: number) => void = (index: number) => {
    const { actions } = this.state;
    const filteredActions = actions.reduce((finalActions, action, i) => {
      if (i !== index) {
        finalActions.push(action);
      }

      return finalActions;
    }, []);

    this.setState({
      actions: filteredActions,
    });
  };

  handleStopEditing: () => void = () => {
    const { cancelEditing, newItem, handleBeingEditedLineContext } = this.props;

    if (newItem) {
      handleBeingEditedLineContext(null);
    } else {
      cancelEditing();
    }
    this.setState({});
  };

  render(): React$Element<any> {
    const {
      currentRenderedShapes,
      loading,
      setShapeColor,
      lineContexts,
      keysAndValuesInUse,
    } = this.props;
    const { type, actions, withinLocationMovementKey, metadata } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <>
        <Row>
          <Row disableBorder>
            <LineTypeMenu
              className="test-3"
              currentUseType={type}
              // $FlowFixMe -> Not sure how to fix this, flow complains because this method needs arguments but I'm not passing arguments to it
              handleLineType={this.handleLineType.bind(this)}
            />
          </Row>
          <Column flexDirection="column">
            <Row>
              <Button icon="plus" text="Add line" onClick={this.addNewLine} />
            </Row>
            <Row disableBorder>
              <LineEditingMenu
                currentRenderedShapes={currentRenderedShapes}
                actions={actions}
                setShapeColor={setShapeColor}
                // $FlowFixMe -> Not sure how to fix this, flow complains because this method needs arguments but I'm not passing arguments to it
                handleSelectLineId={this.handleSelectLineId.bind(this)}
                // $FlowFixMe -> Not sure how to fix this, flow complains because this method needs arguments but I'm not passing arguments to it
                handleCrossingType={this.handleCrossingType.bind(this)}
                handleDeleteAction={this.handleDeleteAction.bind(this)}
              />
            </Row>
          </Column>
          <Column>
            <WithinLocationOptions
              currentUseType={type}
              withinLocationMovementKey={withinLocationMovementKey}
              metadata={metadata}
              currentRenderedShapes={currentRenderedShapes}
              handleAddMetadataItem={this.handleAddMetadataItem.bind(this)}
              handleEditMetadataItem={this.handleEditMetadataItem.bind(this)}
              handleDeleteMetadaItem={this.handleDeleteMetadaItem.bind(this)}
              handleWithinLocationMovementKey={this.handleWithinLocationMovementKey.bind(
                this
              )}
              lineContexts={lineContexts}
              keysAndValuesInUse={keysAndValuesInUse}
            />
          </Column>
        </Row>
        <Row justifyContent="flex-end">
          <Button intent={Intent.PRIMARY} onClick={this.saveLineContext}>
            Save
          </Button>
          <Button intent={Intent.WARNING} onClick={this.handleStopEditing}>
            Cancel
          </Button>
        </Row>
      </>
    );
  }
}

export default EditingItemForm;
