import React, { Component } from 'react';
import history from './history';
import { auth0Init } from '../services/auth0';
import PreInitLoading from '../components/pages/pre-init-loading';

export default class Auth0Wrapper extends Component {
  state = { ready: false };

  async componentDidMount() {
    const auth0Client = await auth0Init();

    const { search } = window.location;
    if (search.includes('code=') && search.includes('state=')) {
      try {
        await auth0Client.handleRedirectCallback();
        history.replace(window.location.pathname);
      } catch (error) {
        history.replace(window.location.pathname);
      }
    }

    this.setState({ ready: true });
  }

  render() {
    const { ready } = this.state;
    const { children } = this.props;
    return ready ? children : <PreInitLoading />;
  }
}
