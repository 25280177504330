import React from 'react';
import PreInitLoading from '../pre-init-loading';
import { getAuth0Client } from '../../../services/auth0';

const LogoutPage = () => {
  const auth0Client = getAuth0Client();
  auth0Client.logout({
    returnTo: window.location.origin
  });
  return <PreInitLoading />;
};

export default LogoutPage;
