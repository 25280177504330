// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Classes, Button } from '@blueprintjs/core';
import {
  WITHIN_LOCATION_MOVEMENT,
  WITHIN_LOCATION_ENTRY,
  WITHIN_LOCATION_PASS_BY,
} from '../../constants/line-context';
import MetadataEditor from './metadata-editor';
import { Col } from './editing-item-form';
import { Row } from './table';

import * as LineContextModel from '../../models/line-context';

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

type Props = {
  lineContexts: Array<LineContextModel.t>,
  currentUseType?: string | null,
  withinLocationMovementKey?: string,
  metadata?: Array<LineContextModel.metadataItem>,
  handleAddMetadataItem(): void,
  handleEditMetadataItem(): void,
  handleDeleteMetadaItem(): void,
  handleWithinLocationMovementKey(): void,
  keysAndValuesInUse?: {
    keys: string[],
    values: string[],
  },
};

const WithinLocationOptions = ({
  lineContexts,
  currentUseType,
  withinLocationMovementKey,
  metadata,
  handleAddMetadataItem,
  handleEditMetadataItem,
  handleDeleteMetadaItem,
  handleWithinLocationMovementKey,
  keysAndValuesInUse,
}: Props) => {
  return (
    <Fragment>
      {currentUseType === WITHIN_LOCATION_MOVEMENT && (
        <input
          type="text"
          value={withinLocationMovementKey}
          width="80%"
          onChange={(e) => handleWithinLocationMovementKey(e)}
          placeholder="Enter a within location line name"
          className={Classes.INPUT}
          style={{ width: '150px' }}
        />
      )}
      {(currentUseType === WITHIN_LOCATION_ENTRY ||
        currentUseType === WITHIN_LOCATION_PASS_BY) && (
        <Col>
          <Row>
            <Col>
              <StyledButton
                icon="plus"
                onClick={handleAddMetadataItem}
                text="Add metadata"
              />
            </Col>
          </Row>
          <Row disableBorder>
            <Col>
              <MetadataEditor
                metadata={metadata}
                handleDeleteMetadaItem={handleDeleteMetadaItem}
                handleEditMetadataItem={handleEditMetadataItem}
                keysAndValuesInUse={keysAndValuesInUse}
              />
            </Col>
          </Row>
        </Col>
      )}
    </Fragment>
  );
};

export default WithinLocationOptions;
