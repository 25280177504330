import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';
import { checkIfWordIsFound } from './../../utils/searchHelpers';

const SearchMenu = ({ searchInput, recordings, history }) => {
  const handleMenuItemRedirect = id => {
    history.push(`/cameras/edit/${id}`);
  };

  const findUniqueOrgs = () => {
    const orgsNames = recordings
      .filter(shape => checkIfWordIsFound(searchInput, shape))
      .map(shape =>
        shape.organisation ? shape.organisation.name : 'no organisation'
      );
    const uniqueOrgsNames = [...new Set(orgsNames)];
    return uniqueOrgsNames;
  };

  const foundOrgs = findUniqueOrgs();

  return (
    <Menu>
      {foundOrgs.map(org => (
        <Fragment key={org}>
          <MenuDivider title={org} />
          {recordings
            .filter(shape => {
              return (
                shape.organisation && shape.organisation.name === org &&
                checkIfWordIsFound(searchInput, shape)
              );
            })
            .map((shape, index) => (
              <MenuItem
                key={`${shape.id}-${index}`}
                label={shape.location ? shape.location.name : ''}
                text={shape.name}
                onClick={() => handleMenuItemRedirect(shape.id)}
              />
            ))}
        </Fragment>
      ))}
    </Menu>
  );
};

export default withRouter(SearchMenu);
