import React, { useState } from 'react';
import { Popover, Position, Button, Switch } from '@blueprintjs/core';
import styled from 'styled-components';
import createNewShapeMenu from './../create-new-shape-menu';
import { LINE } from '../../constants/shapes';

const ShapeTypeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 23px;
  margin-bottom: 1em;
  padding-top: 1em;
  &:first-child {
    padding-top: 0.5em !important;
  }
`;
const SwitchWrapper = styled.div`
  .bp3-control {
    display: inline;
  }
`;
const Text = styled.p`
  font-size: 13px;
  opacity: 0.8;
  margin-bottom: 0;
`;

export const EditShapesHeader = ({
  type,
  title,
  createNewShape,
  handleShowVarLines,
  showVarLines,
}) => {
  const [newShapeNameInput, setNewShapeNameInput] = useState('');

  const handleCreateNewShape = (type) => {
    const newShapeConfigObj = { name: newShapeNameInput, type };
    createNewShape(newShapeConfigObj);
    setNewShapeNameInput('');
  };

  return (
    <ShapeTypeHeader>
      <div>{title}</div>
      {type === LINE && (
        <SwitchWrapper>
          <Text>Show var lines</Text>
          <Switch checked={showVarLines} onChange={handleShowVarLines} />
        </SwitchWrapper>
      )}
      <Popover
        popoverClassName="bp3-popover-content-sizing"
        position={Position.LEFT}
        content={createNewShapeMenu(
          type,
          newShapeNameInput,
          setNewShapeNameInput,
          handleCreateNewShape
        )}
        target={<Button icon="plus">Add</Button>}
      />
    </ShapeTypeHeader>
  );
};
