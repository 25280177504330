import React from 'react';
import styled from 'styled-components';
import { Tag, Button } from '@blueprintjs/core';
import { Row, Column, col1Flex, col2Flex, col3Flex, col4Flex } from './table';
import { AREA } from '../../constants/shapes';
import { LIGHT_GRAY_BLUE } from '../../styles/constants';

const StyledRow = styled(Row)`
  &:hover {
    background-color: ${LIGHT_GRAY_BLUE};
  }
`;

const ItemRow = ({
  areaContext,
  beginEditingItem,
  deleteContext,
  setShapeColor
}) => (
  <StyledRow
    onMouseEnter={() => {
      const shape = {
        id: areaContext.areaId,
        type: AREA
      };
      setShapeColor(shape);
    }}
    onMouseLeave={() => setShapeColor({})}
  >
    <Column flex={col1Flex}>
      <Tag>{areaContext.type}</Tag>
    </Column>
    <Column flex={col2Flex}>
      <Tag>{areaContext.areaId}</Tag>
    </Column>
    <Column flex={col3Flex}>
      {areaContext.taxonomy && <Tag>{areaContext.taxonomy}</Tag>}
    </Column>
    <Column flex={col4Flex} justifyContent={'flex-end'}>
      <Button onClick={() => beginEditingItem(areaContext)}>Edit</Button>
      <Button onClick={() => deleteContext(areaContext)}>Remove</Button>
    </Column>
  </StyledRow>
);

export default ItemRow;
