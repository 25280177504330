// Line Types
export const LOCATION_ENTRY_LINE = 'location-entry';
export const LOCATION_PASS_BY = 'location-pass-by';
export const WITHIN_LOCATION_MOVEMENT = 'within-location-movement';
export const WITHIN_LOCATION_ENTRY = 'within-location-entry';
export const WITHIN_LOCATION_PASS_BY = 'within-location-pass-by';

// Cross directions types
export const IN = 'in';
export const OUT = 'out';
export const IN_OUT = 'in_out';

export const IN_ACTION = [
  {
    direction: IN,
    crossed: true
  },
  {
    direction: OUT,
    crossed: false
  }
];
export const OUT_ACTION = [
  {
    direction: OUT,
    crossed: true
  },
  {
    direction: IN,
    crossed: false
  }
];
export const IN_AND_OUT_ACTION = [
  {
    direction: OUT,
    crossed: true
  },
  {
    direction: IN,
    crossed: true
  }
];
