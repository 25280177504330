import React from 'react';
import {
  ButtonGroup,
  Popover,
  Menu,
  MenuItem,
  Button,
  Intent
} from '@blueprintjs/core';

const FilterRecordingButtons = ({
  allOrgs,
  selectedOrg,
  allLocations,
  selectedLoc,
  setLocFilter,
  setOrgFilter
}) => {
  return (
    <ButtonGroup>
      <Popover>
        <Button icon="people" intent={Intent.WARNING} rightIcon="caret-down">
          {selectedOrg ? selectedOrg.name : 'Filter by organisation'}
        </Button>
        <Menu>
          {allOrgs.map(
            org =>
              !!org &&
              !!org._id && (
                <MenuItem
                  key={org._id}
                  text={org.name}
                  onClick={() => setOrgFilter(org)}
                />
              )
          )}
        </Menu>
      </Popover>
      <Popover>
        <Button icon="shop" intent={Intent.SUCCESS} rightIcon="caret-down">
          {selectedLoc ? selectedLoc.name : 'Filter by location'}
        </Button>
        <Menu>
          {allLocations.map(
            loc =>
              !!loc &&
              !!loc._id && (
                <MenuItem
                  key={loc._id}
                  text={loc.name}
                  onClick={e => setLocFilter(loc)}
                />
              )
          )}
        </Menu>
      </Popover>
    </ButtonGroup>
  );
};

export default FilterRecordingButtons;
