// @flow
import { BLACK, WHITE, RED, GREEN_TRANSPARENT } from '../styles/constants';

const HIT_CONTEXT_LINE_WIDTH = 10;

export function isIntersect(
  XClient,
  YClient,
  XCircle,
  YCircle,
  CircleRadius = 6
) {
  return (
    Math.sqrt((XClient - XCircle) ** 2 + (YClient - YCircle) ** 2) <
    CircleRadius
  );
}

export function drawCircle({
  context,
  XPosition,
  YPosition,
  radius = 6,
  color = BLACK,
}) {
  context.beginPath();
  context.arc(XPosition, YPosition, radius, 0, 2 * Math.PI, false);
  context.fillStyle = color;
  context.fill();
}

export function colorFormater(color: string): string {
  const newColor = color.replace('rgb', 'rgba');
  const finishedColor = newColor.replace(')', ', 0.1)');
  return finishedColor;
}

export function areasRendering(context, areaConfig, isHitContext) {
  const { poly } = areaConfig.points;
  const { color, circleRadius } = areaConfig;

  //Draw area
  context.strokeStyle = color;
  context.fillStyle = color ? colorFormater(color) : GREEN_TRANSPARENT;

  if (isHitContext) {
    context.fillStyle = areaConfig.colorKey;
    context.strokeStyle = areaConfig.colorKey;
  }
  context.beginPath();
  for (const index in poly) {
    if (index === 0) {
      context.moveTo(poly[index][0], poly[index][1]);
    }
    context.lineTo(poly[index][0], poly[index][1]);
  }
  context.closePath();
  context.stroke();
  context.fill();

  //Draw circles points
  if (!isHitContext) {
    for (const index in poly) {
      const circleConfig = {
        context,
        XPosition: poly[index][0],
        YPosition: poly[index][1],
        radius: circleRadius,
        color,
      };
      drawCircle(circleConfig);
    }
  }
}

function drawLine(A, B, ctx) {
  ctx.beginPath();
  ctx.moveTo(A.XPosition, A.YPosition);
  ctx.lineTo(B.XPosition, B.YPosition);
  ctx.stroke();
}

export const drawVarLine = (A, B, varDistance, ctx) => {
  // vector AB
  var ABx = B.XPosition - A.XPosition;
  var ABy = B.YPosition - A.YPosition;
  var ABLength = Math.sqrt(ABx * ABx + ABy * ABy);
  // normalized vector AB
  var NABx = ABx / ABLength;
  var NABy = ABy / ABLength;
  // Perpendicular + normalized vector.
  var perpendicularOffsets = {
    XPosition: -NABy,
    YPosition: NABx,
  };
  const C = {};
  const D = {};
  // compute C and D === A + varDistance * perpendicularOffsets
  C.XPosition = A.XPosition + varDistance * perpendicularOffsets.XPosition;
  C.YPosition = A.YPosition + varDistance * perpendicularOffsets.YPosition;
  D.XPosition = B.XPosition + varDistance * perpendicularOffsets.XPosition;
  D.YPosition = B.YPosition + varDistance * perpendicularOffsets.YPosition;

  ctx.strokeStyle = 'rgb(0,255,0)';

  drawLine(C, D, ctx);
};

export function linesRendering(
  context,
  lineConfig,
  showVarLines,
  isHitContext = false
) {
  const { x0, y0, x1, y1, inverted, var: varLine } = lineConfig.points;
  const { color, circleRadius } = lineConfig;
  const firstCircleConfig = {
    context,
    XPosition: x0,
    YPosition: y0,
    radius: circleRadius,
    color,
  };
  const lastCircleConfig = {
    context,
    XPosition: x1,
    YPosition: y1,
    radius: circleRadius,
    color,
  };

  context.strokeStyle = color;
  if (isHitContext) {
    context.strokeStyle = lineConfig.colorKey;
    context.lineWidth = HIT_CONTEXT_LINE_WIDTH;
  }
  context.beginPath();
  context.moveTo(x0, y0);
  context.lineTo(x1, y1);
  context.closePath();
  context.stroke();

  if (!isHitContext) {
    drawCircle(firstCircleConfig);
    drawCircle(lastCircleConfig);
  }
  const lineMidX = (x1 - x0) / 2 + x0;
  const lineMidY = (y1 - y0) / 2 + y0;
  const r = 20;
  const lineAngle = Math.atan2(y1 - y0, x1 - x0);

  if (showVarLines) {
    drawVarLine(firstCircleConfig, lastCircleConfig, varLine, context);

    drawVarLine(firstCircleConfig, lastCircleConfig, -1 * varLine, context);
  }

  drawLineArrow(context, lineMidX, lineMidY, r, lineAngle, inverted);

  drawCircle(firstCircleConfig);
  drawCircle(lastCircleConfig);
}

export const getPointBeingClicked = (mousePos, shapesList) => {
  let answer = null;
  if (shapesList) {
    shapesList.forEach((shape) => {
      if (shape.visible) {
        if (shape.type === 'line') {
          if (
            isIntersect(
              mousePos.x,
              mousePos.y,
              shape.points.x0,
              shape.points.y0,
              shape.circleRadius
            )
          ) {
            const index = 0;
            answer = { shape, index };
          }
          if (
            isIntersect(
              mousePos.x,
              mousePos.y,
              shape.points.x1,
              shape.points.y1,
              shape.circleRadius
            ) &&
            shape.visible
          ) {
            const index = 1;
            answer = { shape, index };
          }
        }
        if (shape.type === 'area') {
          shape.points.poly.forEach((points, index) => {
            if (
              isIntersect(
                mousePos.x,
                mousePos.y,
                points[0],
                points[1],
                shape.circleRadius
              )
            ) {
              answer = { shape, index };
            }
          });
        }
      }
    });
    return answer;
  }
  return answer;
};

export const getMousePosition = (event, canvas) => {
  const scrollLeft = window.pageXOffset;
  const scrollTop = window.pageYOffset;
  const mousePos = {
    x: event.clientX - canvas.offsetParent.offsetLeft + scrollLeft,
    y: event.clientY - canvas.offsetParent.offsetTop + scrollTop,
  };
  return mousePos;
};

export const getConfPointObj = (
  existingShapes,
  shapeType,
  id,
  pointCoords,
  color = RED,
  circleRadius = 6,
  isIgnoreArea = false,
  visible = true
) => {
  if (shapeType === 'line' && typeof pointCoords.inverted === 'undefined') {
    pointCoords.inverted = false;
  }
  const colorKey = getUniqueRandomColor(existingShapes);
  return {
    id,
    type: shapeType,
    circleRadius,
    points: pointCoords,
    color,
    colorKey,
    isIgnoreArea,
    visible,
  };
};

export function getUniqueRandomColor(allShapes = []) {
  let color = '';
  do {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    color = `rgb(${r},${g},${b})`;
    // eslint-disable-next-line no-loop-func
  } while (allShapes.indexOf((shape) => shape.colorKey === color) >= 0);

  return color;
}

export function getShapeBeingClicked(mousePos, hitCanvas, colorsHash) {
  const hitCtx = hitCanvas.getContext('2d');
  const pixel = hitCtx.getImageData(mousePos.x, mousePos.y, 1, 1).data;
  const color = `rgb(${pixel[0]},${pixel[1]},${pixel[2]})`;
  const shape = colorsHash[color];
  return shape;
}

export const drawLineArrow = (
  ctx,
  fromx,
  fromy,
  r,
  angleOfLine,
  inverted,
  displayArrowOut,
  fillColor = WHITE
) => {
  // convert radians to degrees and add 90 because it's operating on an image
  let degrees = (angleOfLine * 180) / Math.PI + 90;

  // make the angle absolute
  if (degrees < 0) {
    degrees = 360 + degrees;
  }

  // if its over 180, subtract 180
  if (degrees >= 180) {
    degrees -= 180;
  }

  if (inverted) {
    degrees -= 180;
  }

  if (displayArrowOut) {
    degrees -= 180;
  }

  const rotationRadians = (degrees * Math.PI) / 180;

  ctx.translate(fromx, fromy);
  ctx.rotate(rotationRadians);
  ctx.beginPath();

  ctx.moveTo(0, -r);
  ctx.lineTo(r, 0);
  ctx.lineTo(0, r);
  ctx.lineTo(0, -r);
  ctx.closePath();

  ctx.fillStyle = fillColor;
  ctx.fill();
  ctx.rotate(-rotationRadians);
  ctx.translate(-fromx, -fromy);
};

export function doesShapeNameExist(name, type, currentRenderedShapes) {
  const found = currentRenderedShapes.find(
    (shape) => shape.id === name && shape.type === type
  );

  return found !== undefined;
}
