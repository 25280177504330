const { BaseApi } = require('@auravisionlabs/aura-pkg-node-core-api-adapter');
const { AuthApiException } = require('./errors/authApiException');

const bootstrapOrgResource = '/api/v1/bootstrap/';

/**
 * Simple adapter for auth service api
 * This must be constructed with
 * a httpAdapter that implements
 * makeRequest method as defined
 * in AxiosHttpClient.makeRequest
 *
 * NOTE: We are using BaseAPI from
 * aura-pkg-node-core-api-adapter as
 * it gives us a great base for creating
 * this adapter. This can easily be
 * refactored into a new
 * aura-pkg-node-auth-api-adapter in
 * future
 */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getHeaders"] }] */
class AuthAPI extends BaseApi {
  /**
   * Gets bootstrap for a given auth0Id
   * passes token
   *
   * @param auth0Id
   * @param token
   * @param skipTokenCache
   * @param params
   * @param shouldCache
   * @param cacheTtl
   * @param timeout
   * @returns {Promise<{payload: *}>}
   */
  async getBootstrap(
    auth0Id,
    token,
    skipTokenCache = false,
    params = null,
    shouldCache = false,
    cacheTtl = null,
    timeout = 35000,
  ) {
    try {
      this.logger({
        message:
          'AuthAPI.getBootstrap called with '
          + `auth0Id ${auth0Id}, skipTokenCache: ${skipTokenCache} and params: ${params}`,
      });

      if (typeof auth0Id !== 'string') {
        throw new Error(`Auth0 ID is missing or incorrect type: ${auth0Id} (${typeof auth0Id})`);
      }

      const sanitisedAuth0Id = auth0Id.replaceAll('|', '%7C');
      const url = this.decorateUrlQuery(
        `${this.envBasePath}${bootstrapOrgResource}${sanitisedAuth0Id}`,
        { ...params, skipCache: skipTokenCache.toString() },
      );

      const response = await this.makeRequest(
        'GET',
        url,
        await this.getHeaders({}, token),
        null,
        shouldCache,
        cacheTtl,
        timeout,
      );

      return response;
    } catch (error) {
      throw new AuthApiException(
        'AuthAPI.getBootstrap API request has failed',
        error,
      );
    }
  }

  /**
   * Gets the base headers and allows merge of
   * additional headers and dynamic token
   *
   * @param headersToMerge
   * @param token
   * @returns {object}
   */
  async getHeaders(headersToMerge = {}, token) {
    return {
      ...headersToMerge,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    };
  }
}

module.exports = {
  AuthAPI,
};
